import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useObserver } from 'mobx-react';
import { useRouter } from 'next/router';

import { Button as _Button, CheckboxInput } from 'components/core';
import { Typography } from 'src/typography';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import useFeatureFlags from 'hooks/use-feature-flags';
import { ModalNames, useModals } from 'components/modals';

import { EnrollmentSteps, PLAID_TERMS_URL } from 'src/payments/constants';
import { useDutchiePayAnalytics } from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';
import { useActivatePaymentMethod } from 'src/payments/dutchie-pay/signup/views/terms/use-activate-payment-method';
import { DutchiePayHeader } from 'src/payments/components';
import { useRecordUserAcceptance } from './use-record-user-acceptance';

export function TermsForm(): JSX.Element {
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const { trackEnrollmentStepSubmitted } = useDutchiePayAnalytics();
  const { recordUserAcceptance } = useRecordUserAcceptance();
  const { activatePaymentMethod } = useActivatePaymentMethod();
  const FeatureFlags = useFeatureFlags();
  const router = useRouter();
  const { activeModals, closeModal } = useModals();

  const [agree, setAgree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const instorePlaidEmbeddedSearchEnabled =
    FeatureFlags.flags['fintech.platform.dutchiepay.instore-plaid-embedded-search'];
  const plaidEmbeddeedSearchEnabled = FeatureFlags.flags['fintech.platform.dutchiepay.plaid-embedded-search'];

  const useEmbeddedSearch =
    (instorePlaidEmbeddedSearchEnabled && DutchiePayEnrollment.isInstoreSignup) ||
    (plaidEmbeddeedSearchEnabled && !DutchiePayEnrollment.isInstoreSignup);

  const connectedBankAccountDetails = useObserver(() => DutchiePayEnrollment.connectedBankAccountDetails);

  const { ownerName, bankName, lastDigits } = connectedBankAccountDetails || {};

  const date = new Date().toLocaleDateString(`en-US`);

  const onAcceptTermsClick = async (): Promise<void> => {
    setIsLoading(true);
    trackEnrollmentStepSubmitted(EnrollmentSteps.terms);

    const userAcceptance = await recordUserAcceptance();
    if (userAcceptance) {
      await activatePaymentMethod();
      if (activeModals.get(ModalNames.termsOfService)) {
        closeModal(ModalNames.termsOfService, {});
      }
    } else {
      DutchiePayEnrollment.cancelEnrollment();
      void router.push(DutchiePayEnrollment.refUrl);
    }
    setIsLoading(false);
  };

  const continueText = DutchiePayEnrollment.isInstoreSignup ? 'Continue to cart' : 'Continue checkout';

  return (
    <TermsWrapper>
      <DutchiePayHeader role='heading'>Terms of service</DutchiePayHeader>
      <TermsContainer>
        <Terms role='main'>
          <P>Dutchie Pay Terms of Service</P>
          <P>
            Today, {date}, by clicking “I Authorize,” I agree to the{' '}
            <A href='http://dutchie.com/terms' target='_blank'>
              Terms of Service
            </A>
            &nbsp;and authorize Courier Plus Inc., dba Dutchie (“Dutchie”), as agent on behalf of Dispensary or
            Dispensaries (as defined in the Terms) to send instructions to debit my bank account via an electronic fund
            transfer (EFT) for each payment that I initiate confirming the purchase of products on Dutchie or Dispensary
            website. I understand and agree that the payment amount will be the final amount displayed on the Dispensary
            receipt when I receive the products. The amount displayed on Dutchie’s website is an estimate. I also
            authorize Dutchie, as agent on behalf of Dispensary or Dispensaries to send instructions to debit or credit
            my account for refunds or to correct errors.
          </P>

          <p>{ownerName}</p>
          <p>{bankName}</p>
          <P>{lastDigits}</P>

          <P>
            I understand that the initiation of an EFT must comply with the rules of the National Automated Clearing
            House Association (NACHA) and U.S. law. Both Dutchie and I agree to be bound by NACHA Operating Rules as
            they pertain to these transactions. This authorization shall remain in full force and effect until Dutchie
            has received notification from me by achoperations@dutchie.com that I wish to revoke this authorization.
          </P>
          <P>
            For Dutchie to request an EFT under this authorization, I must have the full payment amount available in my
            bank account. If my payment is returned unpaid, I authorize Dutchie or Dispensary, or its agents or assigns,
            to make a one-time EFT from my account to collect a return fee. I understand any debit or credit entry
            returned will result in a reversal of the credit posted.
          </P>
          <P>
            Please print or save a copy of this authorization or contact Dutchie at achoperations@dutchie.com to learn
            how you can obtain a copy.
          </P>
        </Terms>
      </TermsContainer>
      <Controls>
        {useEmbeddedSearch ? (
          <>
            <Typography.Body size='small'>
              By clicking "{continueText}", I agree to the terms and I authorize electronic debits from my account for
              purchases made using Dutchie Pay. I also agree to the{' '}
              <A href={PLAID_TERMS_URL} target='_blank'>
                terms
              </A>{' '}
              related to the use of Electronic Records and Signatures.
            </Typography.Body>
            <Button
              type='button'
              size='large'
              onClick={onAcceptTermsClick}
              disabled={!connectedBankAccountDetails}
              loading={isLoading}
              variant='dutchiePay'
            >
              {continueText}
            </Button>
          </>
        ) : (
          <>
            <CheckboxInput
              height='auto'
              checked={agree}
              onClick={() => setAgree(!agree)}
              option={{
                key: 'agree-terms',
                name: 'agree-terms',
                link: true,
                label: (
                  <>
                    I've read the terms and I authorize electronic debits from my account for purchases made using
                    Dutchie Pay. I also agree to the{' '}
                    <A href={PLAID_TERMS_URL} target='_blank'>
                      Terms
                    </A>{' '}
                    related to the use of Electronic Records and Signatures.
                  </>
                ),
              }}
            />
            <Button
              type='button'
              size='large'
              onClick={onAcceptTermsClick}
              disabled={!agree}
              loading={isLoading}
              variant='dutchiePay'
            >
              Continue
            </Button>
          </>
        )}
      </Controls>
    </TermsWrapper>
  );
}

const TermsWrapper = styled.div`
  position: relative;
`;

const TermsContainer = styled.div`
  ${({ theme: { colors } }) => css`
    --panel-border-color: ${colors.grey[80]};

    background-color: ${colors.grey[95]};
    border: 1px solid var(--panel-border-color);
    border-radius: 8px;
    color: ${colors.grey[35]};
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 25px;
    padding: 24px 8px 2px 24px;
  `}
`;

const Terms = styled.div`
  height: 210px;
  overflow-y: scroll;
  font-size: 12px;
  line-height: 1.65;
  padding-right: 12px;

  // For Firefox
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track-piece:end {
    margin-bottom: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--panel-border-color);
    border-radius: 6px;
  }
`;

const P = styled.p`
  margin-bottom: 1rem;
`;

const A = styled.a`
  ${({ theme: { customized } }) => css`
    color: ${customized.colors.buttonsLinks};
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  `};
`;

const Controls = styled.div`
  ${({ theme: { colors } }) => css`
    color: ${colors.grey[45]};
    display: flex;
    flex-direction: column;
    gap: 24px;
  `}
`;

const Button = styled(_Button)`
  width: auto;
`;
