import React, { forwardRef, memo } from 'react';
import Chip from '@material-ui/core/Chip';
import styled, { css } from 'styled-components';

/**
 * @type {React.FC<PropsWithChildren>}
 */
const BasicChip = forwardRef((props, ref) => <StyledChip {...props} ref={ref} />);

export default memo(BasicChip);

const ChipSizes = {
  large: css`
    display: flex;
    align-items: center;
    border-radius: 17.5px;
    padding: 11px 14px;
  `,
  small: css`
    padding: 3px 10px;
  `,
};

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    font-size: 12px;
    line-height: 13px;
    font-weight: bold;
    padding: 0;
    height: auto;
  }
  .MuiChip-label {
    text-decoration: none;
    ${(props) => ChipSizes[props.size]};
  }
`;

StyledChip.defaultProps = {
  size: `small`,
};
