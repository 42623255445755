import React from 'react';
import { space } from 'styled-system';
import styled from 'styled-components';
import compact from 'lodash/compact';
import Big from 'big.js';

import DeliveryIcon from 'assets/delivery-icon';
import { SvgPickupIcon } from 'assets/pickup-icon';

import useTranslation from 'hooks/use-translation';
import useCart from 'shared/hooks/use-cart';

import { formatDeliveryZoneInfo, formatPickupInfo } from 'shared/helpers/dispensaries';
import { calculateDeliveryFee } from 'shared/order/fees';
import { formatCurrency } from 'shared/helpers/utils';

const TypeInfo = ({ closed, typeInfoMessage }) => (
  <TypeInfoContainer closed={closed}>{typeInfoMessage}</TypeInfoContainer>
);

export const PickupButton = ({ pickupInfo, dispensary, formattedPickupEstimate, offersAfterHours, ...props }) => {
  const { t } = useTranslation();
  const closed = pickupInfo.isClosed;
  const pickupReopenMessage = `will reopen ${pickupInfo.nextService.openString}`;
  const { pickupMinimum } = formatPickupInfo(dispensary, `inStorePickup`);
  const subInfo = compact([t('common.noFee', 'No fee'), pickupMinimum, formattedPickupEstimate]).join(' • ');

  let typeInfoMessage = subInfo;
  if (closed) {
    if (offersAfterHours) {
      typeInfoMessage = t('common.closedPreOrder', 'Closed, but available for pre-order');
    } else {
      typeInfoMessage = `Closed • ${pickupReopenMessage}`;
    }
  }

  return (
    <OrderTypeButtonContainer
      aria-label={t('common.pickup', 'Pickup')}
      disabled={closed && !offersAfterHours}
      {...props}
    >
      <ButtonContent>
        <IconContainer>
          <SvgPickupIcon height={34} width={37} fill={closed ? '#9FB3C3' : '#A3B2C1'} />
        </IconContainer>

        <ButtonDetails>
          <Buttonlabel>{t('common.pickup', 'Pickup')}</Buttonlabel>
          <TypeInfo closed={closed} typeInfoMessage={typeInfoMessage} />
        </ButtonDetails>
      </ButtonContent>
    </OrderTypeButtonContainer>
  );
};

export const DeliveryButton = ({
  deliveryInfo,
  dispensary,
  formattedDeliveryEstimate,
  offersAfterHours,
  hasDeliveryAddress,
  ...props
}) => {
  const { t } = useTranslation();
  const Cart = useCart();

  const deliveryFee = calculateDeliveryFee(`delivery`, dispensary, Big(Cart?.costBreakdown?.subtotal ?? 0));
  const formattedDeliveryFee = formatCurrency(deliveryFee, { trimZeroCents: true });
  let deliveryFeeToDisplay = formattedDeliveryFee === `$0` ? `Free delivery` : `${formattedDeliveryFee} delivery fee`;

  const closed = deliveryInfo.isClosed;
  const { minimumDisplay, bothVary, feeDisplay, fee } = formatDeliveryZoneInfo(dispensary, hasDeliveryAddress, false);
  if (fee === 'varies') {
    deliveryFeeToDisplay = feeDisplay;
  }
  const deliveryFeeMessage = !bothVary && deliveryFeeToDisplay;
  const deliveryMinMessage = bothVary || minimumDisplay;
  const deliveryReopenMessage = `will reopen ${deliveryInfo.nextService.openString}`;

  const deliveryMinimum = deliveryMinMessage;
  const subInfo = compact([deliveryFeeMessage, deliveryMinimum, formattedDeliveryEstimate]).join(' • ');

  let typeInfoMessage = subInfo;
  if (closed) {
    if (offersAfterHours) {
      typeInfoMessage = t('common.closedPreOrder', 'Closed, but available for pre-order');
    } else {
      typeInfoMessage = `Closed • ${deliveryReopenMessage}`;
    }
  }

  return (
    <OrderTypeButtonContainer
      aria-label={t('common.delivery', 'Delivery')}
      disabled={closed && !offersAfterHours}
      {...props}
    >
      <ButtonContent>
        <IconContainer pt={2}>
          <DeliveryIcon height={33} width={55} fill={closed ? '#9FB3C3' : '#A3B2C1'} />
        </IconContainer>

        <ButtonDetails>
          <Buttonlabel>{t('common.delivery', 'Delivery')}</Buttonlabel>
          <TypeInfo closed={closed} typeInfoMessage={typeInfoMessage} />
        </ButtonDetails>
      </ButtonContent>
    </OrderTypeButtonContainer>
  );
};

const OrderTypeButtonContainer = styled.button`
  height: 95px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primaryGrey};
  border: ${({ theme }) => `1px solid ${theme.colors.blueGrey[80]}`};
  border-radius: 10px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer') /* eslint-disable-line */};

  &:hover {
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.primaryGrey : theme.colors.blueGrey[95])};
    border: ${({ theme, disabled }) => `1px solid ${disabled ? theme.colors.blueGrey[80] : theme.colors.grey[70]}`};
  }

  ${space}
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const IconContainer = styled.div`
  width: 92px;

  ${space}
`;

const Buttonlabel = styled.label`
  color: ${({ theme }) => theme.colors.grey[45]};
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  text-align: initial;
`;

const ButtonDetails = styled.div`
  text-align: initial;
`;

const TypeInfoContainer = styled.div`
  padding-top: 5px;
  font-size: 13px;
  color: ${({ theme, closed }) => (closed ? theme.colors.grey[60] : theme.colors.grey[30])};
  line-height: 14px;
`;
