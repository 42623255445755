import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import useUI from 'hooks/use-ui';
import useTranslation from 'hooks/use-translation';

import { ARIA_ROLES } from 'shared/constants';
import { EmbeddedExternalLink, Link } from 'components/core';

const PoweredByDutchie = observer(() => {
  const { t } = useTranslation();
  const { isEmbedded } = useUI();

  const LinkWrapper = isEmbedded ? EmbeddedExternalLink : Link;

  return (
    <Container role={ARIA_ROLES.CONTENT_INFO} className='privacy-policy-link'>
      <LinkWrapper href='https://dutchie.com/privacy'>
        <Text>{t('footer.privacy', 'Privacy Policy')}</Text>
      </LinkWrapper>
    </Container>
  );
});

export default PoweredByDutchie;

const Container = styled.div`
  width: 100vw;
  height: 30px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.blueGrey[80]}`};
  box-shadow: 0px -2.62px 2px rgba(0, 0, 0, 0.042);
  background-color: white;
  z-index: 10;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.grey[35]};
  font-size: 12px;
  line-height: 20px;
  margin-right: 5px;
  text-decoration: underline;

  :hover {
    color: #121516;
  }
`;
