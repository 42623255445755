import _ from 'lodash';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useObserver } from 'mobx-react-lite';
import { useQuery } from '@apollo/react-hooks';
import { useRouter } from 'next/router';

import { StrainTypeFilterOptions, AllSubcategories } from 'shared/constants';

import { isMedOnly } from 'shared/core/helpers/dispensaries';
import {
  getCategoriesForMenuForState,
  getSubcategoryOptionsForState,
  isWeightedCategory,
} from 'shared/helpers/products';
import useDispensary, { getDispensary } from 'src/dispensary/hooks/use-dispensary';
import { useDispensaryCategory, getCategory } from './use-dispensary-category';
import useProductQueryParams from './use-product-query-params';
import useCart from './use-cart';
import useUI from './use-ui';
import { useHideDiscountFromConsumer } from 'shared/hooks/use-hide-discounts-from-consumer';

// eslint-disable-next-line no-use-before-define
export const getQuery = () => menuFilters;
export const getVariables = ({
  dispensary,
  category,
  skipCurrentFilters,
  search,
  pricingType,
  isOnSpecial = false,
  specialId,
  specialType,
  isKioskMenu,
  onSearchPage,
}) => {
  const variables = {
    dispensaryId: dispensary?.id,
    pricingType,
    specialId,
    specialType,
    isKioskMenu,
  };

  if (!skipCurrentFilters) {
    if (category?.value && !onSearchPage) {
      variables.category = category.value;
    }

    if (search) {
      variables.search = search;
    }
    if (isOnSpecial) {
      variables.isOnSpecial = isOnSpecial;
    }
  }
  return variables;
};

export const getMenuFilterOptions = async (apolloClient, props) => {
  const { data } = await getDispensary(apolloClient, props);
  const dispensary = get(data, `filteredDispensaries[0]`);
  const category = getCategory(_.toLower(props.params?.category));
  const pricingType = isMedOnly(dispensary || {}) ? `med` : `rec`;
  const { isKioskMenu } = props;
  return apolloClient.query({
    query: getQuery(),
    variables: getVariables({ dispensary, category, skipCurrentFilters: true, pricingType, isKioskMenu }),
    skip: !dispensary,
  });
};

const getCategoriesSubcategoriesAsOptions = (categoriesSubcategoriesQueryResult, state) => {
  const categoriesForMenu = getCategoriesForMenuForState(state);
  const subcategoriesForMenu = getSubcategoryOptionsForState(state);

  const categoryOptionMap = {};
  _.forEach(categoriesForMenu, (categoryOption) => {
    categoryOptionMap[categoryOption.value] = categoryOption;
  });

  const categoriesSubcategoriesAsOptions = _.filter(
    _.map(categoriesSubcategoriesQueryResult, (categorySubcategory) => {
      const subcategoriesSet = new Set(categorySubcategory.subcategories);
      return {
        category: categoryOptionMap?.[categorySubcategory.category],
        subcategories: _.filter(subcategoriesForMenu?.[categorySubcategory.category], ({ value }) =>
          subcategoriesSet.has(value)
        ),
      };
    }),
    (catSubOption) => !!catSubOption?.category
  );

  return categoriesSubcategoriesAsOptions;
};

/**
 * @returns {{
 *  brands: import('types/graphql').GqlBrand[]
 *  apolloValues: ApolloQueryResult
 * } & Record<string, unknown> }
 */
export default function useMenuFilterOptions(props) {
  const cart = useCart();
  const category = useDispensaryCategory();
  const router = useRouter();
  const { dispensary } = useDispensary();
  const { queryParams } = useProductQueryParams();
  const isOnSpecial = _.includes(router.pathname, 'specials');
  const UI = useUI();
  const { onSearchPage } = UI;
  const isKioskMenu = useObserver(() => UI.isKiosk);

  const variables = getVariables({
    dispensary,
    category,
    skipCurrentFilters: props?.skipCurrentFilters,
    search: queryParams?.search,
    pricingType: cart?.menuType,
    isOnSpecial,
    specialId: props?.specialId,
    specialType: props?.specialType,
    isKioskMenu,
    onSearchPage,
  });

  const { data, ...apolloValues } = useQuery(getQuery(), {
    variables,
    skip: !dispensary,
  });

  const strainTypes = _.compact(
    _.map(_.get(data, `menuFiltersV2.strainTypes`, []), (strainType) =>
      _.find(StrainTypeFilterOptions, { value: strainType })
    )
  );

  const subcategoriesFromQuery = _.get(data, `menuFiltersV2.subcategories`, []);
  const subcategories = _.filter(AllSubcategories, ({ value }) => !!_.includes(subcategoriesFromQuery, value));
  const uniqueSubcategories = _.uniqBy(subcategories, `key`);

  const categoriesSubcategoriesFromQuery = _.get(data, `menuFiltersV2.categoriesSubcategories`, []);

  const categoriesSubcategoriesAsOptions = getCategoriesSubcategoriesAsOptions(
    categoriesSubcategoriesFromQuery,
    dispensary?.location?.state
  );

  let weights = [];
  if (isWeightedCategory(category?.value) || isOnSpecial) {
    weights = _.get(data, `menuFiltersV2.weights`, []);
  }

  const brands = _.sortBy(_.get(data, `menuFiltersV2.brands`, []), ({ name }) => _.toLower(name));
  const hasProductsOnSpecial = _.get(data, `menuFiltersV2.hasProductsOnSpecial`, false);
  const effects = _.get(data, `menuFiltersV2.effects`, []);
  const hideDiscounts = useHideDiscountFromConsumer(dispensary);

  return {
    brands,
    strainTypes,
    subcategories: uniqueSubcategories,
    categoriesSubcategories: categoriesSubcategoriesAsOptions,
    weights,
    hasProductsOnSpecial,
    apolloValues,
    effects,
    hideDiscounts,
  };
}

export const menuFilters = gql`
  query MenuFiltersV2(
    $category: String
    $dispensaryId: String!
    $search: String
    $pricingType: menuPricingTypes
    $isOnSpecial: Boolean
    $specialId: String
    $specialType: String
    $isKioskMenu: Boolean
    $hasProductsOnSpecial: Boolean
  ) {
    menuFiltersV2(
      category: $category
      dispensaryId: $dispensaryId
      search: $search
      pricingType: $pricingType
      isOnSpecial: $isOnSpecial
      specialId: $specialId
      specialType: $specialType
      isKioskMenu: $isKioskMenu
      hasProductsOnSpecial: $hasProductsOnSpecial
    ) {
      brands {
        id
        name
      }
      strainTypes
      subcategories
      categoriesSubcategories {
        category
        subcategories
      }
      weights
      hasProductsOnSpecial
      effects
    }
  }
`;
