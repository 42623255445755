import { useMemo } from 'react';

import { GqlDispensaryBrandsQueryVariables, useDispensaryBrandsQuery } from 'types/graphql';
import { fromGenericCategoryRoute } from 'src/generics/data/generic-dispensary-category/mappings';
import { GraphqlError } from 'src/errors';
import { sortByName } from 'shop/utils';

import useProductQueryParams from 'hooks/use-product-query-params';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useCategoryRouteParam } from 'shop/hooks/use-category-route-param';

import { useBrandsSearch, useBrandsSelection, useFilteredOptions } from '../brands-filter-drawer.utils';
import { TransformBrandsParams, UseVariablesParams } from './dispensary-brands-filter-drawer.types';

import { TransformBrandsReturn, UseBrandsFilterReturn } from '../brands-filter-drawer.types';

function useTransformBrandsForFiltering({ data, category, loading }: TransformBrandsParams): TransformBrandsReturn {
  const brands = data?.menuFiltersV2?.brands;

  return useMemo(() => {
    if (loading) {
      return undefined;
    }

    if (!brands || !brands.length) {
      console.error(`brands data not found for category (${String(category)})`);
      return undefined;
    }

    return brands
      .map((brand: { id: string; name: string }) => ({ key: brand.id, label: brand.name, name: brand.name }))
      .sort(sortByName);
  }, [brands, category, loading]);
}

function useVariables({ category }: UseVariablesParams): GqlDispensaryBrandsQueryVariables | undefined {
  const { dispensary } = useDispensary();

  if (!category) {
    return undefined;
  }

  const transformedCategory = fromGenericCategoryRoute.get(category);

  return { category: transformedCategory, dispensaryId: dispensary.id };
}

export function useDispensaryBrandsFilter(): UseBrandsFilterReturn {
  const {
    setQueryParams,
    queryParams: { brands },
  } = useProductQueryParams();

  const { category } = useCategoryRouteParam();
  const { handleToggle, handleClear, handleIsSelected, selectedBrands } = useBrandsSelection({ brands });
  const { inputValue, debouncedValue, handleChangeInputValue, handleClearInputValue } = useBrandsSearch();

  const variables = useVariables({ category });

  const { data, loading, error } = useDispensaryBrandsQuery({
    skip: !variables,
    variables,
  });

  if (error) {
    throw new GraphqlError(error);
  }

  const options = useTransformBrandsForFiltering({ data, category, loading });

  const filteredOptions = useFilteredOptions({ options, debouncedValue });

  const handleSave = (): void => {
    setQueryParams({ brands: Array.from(selectedBrands) });
  };

  return {
    loading,
    inputValue,
    filteredOptions,
    handleToggle,
    handleClear,
    handleSave,
    handleChangeInputValue,
    handleClearInputValue,
    handleIsSelected,
  };
}
