import React, { forwardRef, ReactFragment } from 'react';
import styled from 'styled-components';
import {
  color,
  height,
  HeightProps,
  justifyContent,
  JustifyContentProps,
  width,
  WidthProps,
  space,
  SpaceProps,
  borderRadius,
} from 'styled-system';

const StyledCard = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: ${({ theme }) => theme.transitions.create(['box-shadow'], { duration: 200 })};
  border-radius: 13px;
  position: relative;
  box-shadow: ${({ alwaysShowShadow }) => (alwaysShowShadow ? '0px 4px 10px rgba(0, 0, 0, 0.10)' : 'none')};
  height: 187px;
  max-width: unset;
  width: auto;
  ${borderRadius}
  ${color}
  ${height}
  ${justifyContent}
  ${space}
  ${width}
  user-select: none;
  -webkit-user-drag: none;

  @media (min-width: 600px) and (hover: hover) {
    &:hover,
    &:active {
      box-shadow: 0 5px 14px rgba(0, 0, 0, 0.1);
    }
  }
`;

type StyleProps = HeightProps & JustifyContentProps & SpaceProps & WidthProps;

type CardProps = StyleProps & {
  children?: Element | ReactFragment;
};

export const Card = forwardRef((props: CardProps, ref) => <StyledCard ref={ref} {...props} />);

export default Card;
