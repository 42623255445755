import React, { forwardRef } from 'react';
import styled from 'styled-components';

import GlobalHeader from 'components/global-header';
import GlobalFooter from 'components/global-footer-rebrand';

type LayoutWithHeaderProps = {
  children: React.ReactNode;
  hideFooter?: boolean;
};

const LayoutWithHeader = forwardRef<HTMLDivElement, LayoutWithHeaderProps>((props, ref) => {
  const { hideFooter, children, ...otherProps } = props;

  return (
    <Container ref={ref}>
      <GlobalHeader />
      <Content {...otherProps}>{children}</Content>
      {!hideFooter && <GlobalFooter />}
    </Container>
  );
});

export default LayoutWithHeader;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const Content = styled.div`
  flex: 1 0 0%;
`;
