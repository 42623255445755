/* eslint-disable lodash/prefer-lodash-method */
import { css } from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import mediaQuery from 'css-mediaquery';
import _ from 'lodash';

import { MuiAccordion, MuiAccordionSummary, MuiAccordionActions } from 'shared/components/accordion';

import { Fonts } from 'src/typography';
import { InterfaceVariants } from 'src/constants/interface-variants';

const normalTransitions = createMuiTheme().transitions;
const highPerformanceTransitions = {
  create: _.constant('none'),
};

const normalAnimations = {
  create: (animation, settings) =>
    css`
      ${animation} ${settings}
    `,
};
const highPerformanceAnimations = {
  create: _.constant('none'),
};

export const getStyledTheme = ({
  colors,
  embeddedMenu,
  shadows,
  breakpoints,
  highPerformanceMode,
  spaces,
  customized,
}) => {
  // stupid styled system stuff
  breakpoints.map = (...args) => Object.values(breakpoints.values).map(...args);
  return {
    breakpoints,
    colors,
    embeddedMenu,
    fontFamily: Fonts.proximaNova,
    shadows,
    animations: highPerformanceMode ? highPerformanceAnimations : normalAnimations,
    transitions: highPerformanceMode ? highPerformanceTransitions : normalTransitions,
    highPerformanceMode,
    spaces,
    customized,
  };
};

export const getMuiTheme = ({ colors, customized, uiVariant, highPerformanceMode }) => {
  const ssrMatchMedia = (query) => ({
    matches: mediaQuery.match(query, {
      // The estimated CSS width of the browser.
      // use 0 so we force the most mobile of mobile views
      // most of our traffic is mobile so it should be the first class experience
      width: '0px',
    }),
  });

  const muiTabIndicatorColor =
    uiVariant === InterfaceVariants.dutchieMain ? colors.primaryGreen : customized.colors.buttonsLinks;

  return createMuiTheme({
    palette: { ...colors, primary: { main: colors.primaryBlue }, customized },
    overrides: {
      MuiAccordion,
      MuiAccordionSummary,
      MuiAccordionActions,
      MuiMenu: {
        paper: {
          width: '187px',
        },
        list: {
          padding: 0,
          '& li:first-child': {
            height: `42px`,
            alignItems: `flex-end`,
          },
          '& li:last-child': {
            height: `42px`,
            alignItems: `flex-start`,
          },
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: '14px',
          height: '36px',
          paddingLeft: '29px',
          color: colors.grey[25],
          background: colors.white,
          '&:hover': {
            background: `${colors.primaryGrey} !important`,
          },
          '&$selected': {
            fontWeight: 'bold',
            '&:hover': {
              background: `${colors.primaryGrey} !important`,
            },
          },
        },
      },
      MuiTabs: {
        root: {
          minHeight: 32,
          borderBottom: `1px solid ${colors.blueGrey[90]}`,
        },
        indicator: {
          backgroundColor: muiTabIndicatorColor,
        },
      },
      MuiTab: {
        root: {
          lineHeight: '18px',
          fontSize: '16px',
          fontWeight: 'bold',
          textTransform: 'none',
          minHeight: 30,
          padding: '6px 17px',
          margin: '0px 18px',
          minWidth: 'auto !important',
          color: colors.grey[30],

          '&$textColorInherit': {
            opacity: 1,
          },

          '&$selected': {
            color: muiTabIndicatorColor,
          },
        },
      },
      MuiPopover: {
        paper: {
          backgroundColor: colors.white,
          borderRadius: '8px',
          boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.2)',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
      },
      MuiListItem: {
        root: {
          '&$selected': { backgroundColor: 'initial', fontWeight: 'bold' },
        },
      },
      MuiInputBase: {
        root: {
          fontFamily: customized.fonts.secondary,
        },
      },
      MuiFormLabel: {
        root: {
          fontFamily: customized.fonts.secondary,
        },
      },
    },
    props: {
      MuiUseMediaQuery: { ssrMatchMedia },
      MuiButtonBase: {
        disableRipple: true, // No ripple in the whole application
      },
      MuiSelect: {
        variant: 'outlined',
        autoWidth: true,
      },
      MuiOutlinedInput: { notched: false },
    },
    animations: highPerformanceMode ? highPerformanceAnimations : normalAnimations,
    transitions: highPerformanceMode ? highPerformanceTransitions : normalTransitions,
    highPerformanceMode,
    customized,
  });
};
