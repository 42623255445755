import _toConsumableArray from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _map from "lodash/map";
import _keys from "lodash/keys";
import _uniq from "lodash/uniq";
import _isNil from "lodash/isNil";
import _omitBy from "lodash/omitBy";
import _sortBy from "lodash/sortBy";
import _values from "lodash/values";
import _flatMap from "lodash/flatMap";
import _reduce from "lodash/reduce";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { toSchemaV4 } from 'shared/helpers/specials/schema-mapper/schema-mapper';
import { isSpecialVersion3dot5 } from 'shared/helpers/specials-3-5';
export var getAllSpecialsForCart = function getAllSpecialsForCart(_ref) {
  var dispensary = _ref.dispensary,
      globalSpecialsSettings = _ref.globalSpecialsSettings,
      items = _ref.items,
      medicalOrder = _ref.medicalOrder,
      outputSpecialVersion = _ref.outputSpecialVersion;

  var specialsHash = _reduce(_flatMap(items, function (_ref2) {
    var specialData = _ref2.specialData;
    return mergeSpecials(specialData === null || specialData === void 0 ? void 0 : specialData.bogoSpecials, specialData === null || specialData === void 0 ? void 0 : specialData.saleSpecials) || [];
  }), function (allSpecials, currSpecial) {
    var _currSpecial$specialI;

    var specialValidation = validateCartSpecial(currSpecial, medicalOrder, outputSpecialVersion === 4);

    if (specialValidation.isInvalid) {
      console.warn('WARNING: SPECIAL ', currSpecial, " IS BEING IGNORED. REASON: ".concat(specialValidation.reason));
      return allSpecials;
    }

    var specialId = (_currSpecial$specialI = currSpecial.specialId) !== null && _currSpecial$specialI !== void 0 ? _currSpecial$specialI : currSpecial._id;
    var previousSpecial = allSpecials[specialId];
    allSpecials[specialId] = mergeSpecialPair(previousSpecial, currSpecial, globalSpecialsSettings);
    return allSpecials;
  }, {});

  var updatedSpecials = _flatMap(_values(specialsHash), function (special) {
    return outputSpecialVersion === 4 ? toSchemaV4(special, dispensary) : special;
  });

  return _sortBy(updatedSpecials, function (special) {
    if (special.isRecurring) {
      return special.recurringEndDate ? Number(special.recurringEndDate) : Infinity;
    }

    return special.endStamp ? Number(special.endStamp) : Infinity;
  });
};
export var mergeSpecialPair = function mergeSpecialPair(special1, special2, globalSpecialsSettings) {
  if (!special1 && !special2) {
    return {};
  }

  if (!special1) {
    return _objectSpread(_objectSpread({}, special2), {}, {
      discountPrecedence: special2.discountPrecedence || globalSpecialsSettings.discountPrecedence
    });
  }

  if (!special2) {
    return _objectSpread(_objectSpread({}, special1), {}, {
      discountPrecedence: special1.discountPrecedence || globalSpecialsSettings.discountPrecedence
    });
  }

  return _omitBy(_objectSpread(_objectSpread({}, special1), {}, {
    bogoRewards: mergeCriteriaArrays(special1.bogoRewards, special2.bogoRewards),
    discountPrecedence: special1.discountPrecedence || globalSpecialsSettings.discountPrecedence
  }), _isNil);
};
export var criteriaReducer = function criteriaReducer(criteriaMap, criteria) {
  criteriaMap[criteria._id] = criteria;
  return criteriaMap;
};
export var mergeCriteriaArrays = function mergeCriteriaArrays(criteriaArray1, criteriaArray2) {
  if (!criteriaArray1) {
    return criteriaArray2;
  }

  if (!criteriaArray2) {
    return criteriaArray1;
  }

  var criteriaMap1 = _reduce(criteriaArray1, criteriaReducer, {});

  var criteriaMap2 = _reduce(criteriaArray2, criteriaReducer, {});

  return _flatMap(_uniq([].concat(_toConsumableArray(_keys(criteriaMap1)), _toConsumableArray(_keys(criteriaMap2)))), function (criteriaId) {
    return mergeCriteria(criteriaMap1[criteriaId], criteriaMap2[criteriaId]);
  });
};
export var mergeCriteria = function mergeCriteria(criteria1, criteria2) {
  if (!criteria1) {
    return criteria2;
  }

  if (!criteria2) {
    return criteria1;
  }

  return _omitBy(_objectSpread(_objectSpread({}, criteria1), {}, {
    brandIds: mergeArray(criteria1.brandIds, criteria2.brandIds),
    categoryIds: mergeArray(criteria1.categoryIds, criteria2.categoryIds),
    productIds: mergeArray(criteria1.productIds, criteria2.productIds),
    subcategoryIds: mergeArray(criteria1.subcategoryIds, criteria2.subcategoryIds),
    strainIds: mergeArray(criteria1.strainIds, criteria2.strainIds),
    vendorIds: mergeArray(criteria1.vendorIds, criteria2.vendorIds),
    exclusions: mergeCriteriaArrays(criteria1.exclusions, criteria2.exclusions),
    inventoryTags: mergeArray(criteria1.inventoryTags, criteria2.inventoryTags),
    productTags: mergeArray(criteria1.productTags, criteria2.productTags)
  }), _isNil);
};
export var mergeArray = function mergeArray(array1, array2) {
  if (!array1) {
    return array2;
  }

  if (!array2) {
    return array1;
  }

  return _uniq(array1.concat(array2));
};

var addSpecialType = function addSpecialType(specials, specialType) {
  return _map(specials, function (special) {
    return _objectSpread(_objectSpread({}, special), {}, {
      specialType: specialType
    });
  });
};

export var mergeSpecials = function mergeSpecials(bogoSpecials, saleSpecials) {
  return [].concat(_toConsumableArray(addSpecialType(bogoSpecials !== null && bogoSpecials !== void 0 ? bogoSpecials : [], 'bogo')), _toConsumableArray(addSpecialType(saleSpecials !== null && saleSpecials !== void 0 ? saleSpecials : [], 'sale')));
};
export var validateCartSpecial = function validateCartSpecial(special, medicalOrder, checkIfSpecialVersion3dot5) {
  var bogoRewards = special.bogoRewards,
      discountToCart = special.discountToCart,
      discountBundle = special.discountBundle,
      itemsForAPrice = special.itemsForAPrice,
      menuType = special.menuType,
      products = special.products,
      saleDiscounts = special.saleDiscounts,
      specialType = special.specialType;
  var reason;
  var invalidBogo = specialType === 'bogo' && (bogoRewards || []).length < 1 && !(itemsForAPrice !== null && itemsForAPrice !== void 0 && itemsForAPrice.enabled) && !(discountToCart !== null && discountToCart !== void 0 && discountToCart.enabled) && !(discountBundle !== null && discountBundle !== void 0 && discountBundle.enabled);
  var invalidSale = specialType === 'sale' && (products !== null && products !== void 0 ? products : []).length < 1 && (saleDiscounts !== null && saleDiscounts !== void 0 ? saleDiscounts : []).length < 1;
  var invalidMenuType = menuType === 'medical' && !medicalOrder || menuType === 'recreational' && medicalOrder;

  if (checkIfSpecialVersion3dot5 && !isSpecialVersion3dot5(special)) {
    reason = "Special is not in v3.5 format";
  } else if (invalidBogo || invalidSale) {
    reason = "".concat(invalidBogo ? 'Bogo' : 'Sale', " special does not contain required data");
  } else if (invalidMenuType) {
    reason = 'Special menuType does not match order menuType';
  }

  return {
    isInvalid: !!reason,
    reason: reason
  };
};