import _ from 'lodash';
import { useEffect } from 'react';
import Router from 'next/router';

import useUI from 'hooks/use-ui';
import useProductQueryParams from 'hooks/use-product-query-params';
import useDispensaryMenuSections from 'src/dispensary/hooks/use-dispensary-menu-sections';

import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';
import ROUTES from 'src/routes';
const { DISPENSARY_CNAME } = ROUTES;

function determineScrollPos(viewport) {
  if (!viewport) {
    return {
      x: 0,
      y: 0,
    };
  }
  const { offsetTop, parentOffset } = viewport;
  return {
    x: 0,
    y: offsetTop + parentOffset || parentOffset || window?.scrollY,
  };
}

function saveScrollPos(url, viewport) {
  const { x, y } = determineScrollPos(viewport);
  const scrollPos = { x, y };
  setPersistedValue(url, scrollPos);
}

function restoreScrollPos(url, UI) {
  const scrollPos = getPersistedValue(url);
  if (scrollPos) {
    UI?.embeddedEventDispatcher(`iframe:goBack`, { y: scrollPos.y });
    window.scrollTo(scrollPos.x, scrollPos.y);
    resetScrollPos(url);
  }
}

function resetScrollPos(url) {
  setPersistedValue(url, null);
}

export function useScrollRestoration({ router, viewport, skip }) {
  const UI = useUI();
  const { queryParams } = useProductQueryParams();
  const { menuSections } = useDispensaryMenuSections();

  determineScrollPos(viewport);
  useEffect(() => {
    if (skip) {
      return;
    }

    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';

      const onBeforeUnload = (event) => {
        saveScrollPos(router.asPath, viewport);
        delete event.returnValue;
      };

      const onRouteChangeStart = () => {
        UI.setSubheaderIsSticking(!UI.isDispensaryHeaderInView);
        UI.previousSearchTerm = queryParams.search;
        saveScrollPos(router.asPath, viewport);
      };

      const onRouteChangeComplete = (url) => {
        if (UI.shouldRestorePreviousScroll) {
          UI.setShouldRestorePreviousScroll(false);
          return restoreScrollPos(url, UI);
        }

        const onDispensaryPage = _.includes(router.pathname, 'dispensary');
        const shouldScrollToSubheader = UI.isDutchieMain && UI.subheaderIsSticking && onDispensaryPage;
        if (shouldScrollToSubheader) {
          const onDispoHomepageWithoutMenuSections = router.pathname === DISPENSARY_CNAME && _.isEmpty(menuSections);

          const headerOffset = UI.globalHeaderHeight + UI.dispensaryHeaderHeight;
          window.scrollTo(0, onDispoHomepageWithoutMenuSections ? 0 : headerOffset);
        } else {
          window.scrollTo(0, 0);
        }
      };

      window.addEventListener('beforeunload', onBeforeUnload);
      Router.beforePopState(() => {
        UI.setShouldRestorePreviousScroll(true);
        return true;
      });
      Router.events.on('routeChangeStart', onRouteChangeStart);
      Router.events.on('routeChangeComplete', onRouteChangeComplete);

      // eslint-disable-next-line consistent-return
      return () => {
        Router.events.off('routeChangeStart', onRouteChangeStart);
        Router.events.off('routeChangeComplete', onRouteChangeComplete);
      };
    }
  }, [router, viewport, UI, skip, queryParams.search]);
}
