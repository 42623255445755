import React from 'react';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { breakpoints } from 'components/carousel-slider/helpers';
import Skeleton from './loading-skeleton';

const hybridProductCardLoadingSkeleton = (props) => {
  const flags = useFlags();
  const isAddToCartCTAEnabled = flags['growth.ecomm.add-to-cart-cta.experiment'] ?? false;
  const { dimensions } = props;

  const mobileSize = dimensions.mobile + dimensions.padding * 2;
  const desktopSize = dimensions.desktop + dimensions.padding * 2;

  return (
    <SkeletonWrapper>
      <StyledImageSkeleton mobileSize={mobileSize} desktopSize={desktopSize} />
      <SkeletonContentContainer>
        <Skeleton width={53} height={8} />
        <Skeleton width={45} height={8} />
        <Skeleton height={10} />
        <Skeleton height={10} />
        <Skeleton width={94} height={8} />
        <Inline>
          <Skeleton width={59} height={8} mr={10} />
          <Skeleton width={59} height={8} />
        </Inline>
        {isAddToCartCTAEnabled && <ButtonSkeleton height={35} />}
      </SkeletonContentContainer>
    </SkeletonWrapper>
  );
};

export default hybridProductCardLoadingSkeleton;

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledImageSkeleton = styled(Skeleton)`
  height: ${({ desktopSize }) => desktopSize}px;
  width: ${({ desktopSize }) => desktopSize}px;

  @media (max-width: ${breakpoints.tablet}px) {
    height: ${({ mobileSize }) => mobileSize}px;
    width: ${({ mobileSize }) => mobileSize}px;
  }
`;

const SkeletonContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 130px;
  max-width: 85%;
  margin-top: 12px;
`;

const ButtonSkeleton = styled(Skeleton)`
  border-radius: 100px;
  margin-top: 10px;
`;

const Inline = styled.div`
  display: flex;
`;
