import React from 'react';
import styled from 'styled-components';

import HybridSkeletonCarousel from './hybrid-skeleton-carousel';

import { useCardDimensions } from 'src/components/product-card/product-card.utils';

const HybridCarouselLoadingState = (props) => {
  const defaultDimensions = useCardDimensions({ gutter: 12 });
  const { dimensions = defaultDimensions } = props;

  return (
    <Container>
      <HybridSkeletonCarousel dimensions={dimensions} {...props} />
    </Container>
  );
};

export default HybridCarouselLoadingState;

const Container = styled.div`
  overflow: hidden;

  .carousel-prev-button,
  .carousel-next-button {
    display: none;
  }

  .carousel-start-watcher {
    padding-left: 0;
  }
`;
