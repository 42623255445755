/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'components/core';

import { AccordionBanner } from 'src/payments/components/accordion-banner/accordion-banner';
import FiveDollarDiscount from 'src/assets/five-dollar-discount';
import BoltIcon from 'src/assets/bolt';
import { mediaQueries } from 'shared/styles';
import { CheckoutLabelGrouping } from 'checkout/components/styles/common';

export const FiveDollarDiscountBanner = (): JSX.Element => (
  <AccordionBanner
    marginBottom='-16px'
    borderRadius='4px 4px 0 0'
    graphic={<FiveDollarDiscount />}
    mainContent={
      <span>
        Get $5 off your order the first time you use <strong>Pay by Bank</strong>
      </span>
    }
    toggleContent={
      <span>
        Select “Pay by Bank” as your Payment option, link your bank & checkout. Only valid the first time you use Pay by
        Bank to pay for your order. <Link href='/terms'>Terms apply</Link>
      </span>
    }
  />
);

export const FiveDollarDiscountBannerWithCTA = (): JSX.Element => (
  <CheckoutLabelGrouping mb='20px'>
    <Container>
      <BoltIcon width='50px' />
      <span>
        Save $5 when you use Pay by Bank to checkout. <Link href='/pay/signup'>Link your bank now.</Link>
      </span>
    </Container>
  </CheckoutLabelGrouping>
);

const Container = styled.div`
  ${({ theme: { colors } }) => css`
    align-items: center;
    background-color: ${colors.green20};
    border-radius: 4px;
    display: flex;
    height: 65px;
    justify-content: flex-start;
    width: 390px;
    color: ${colors.green90};
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    width: 340px;
    margin-top: 20px;

    span {
      max-width: 250px;
    }

    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media ${mediaQueries.largePhone} {
    width: 100%;

    span {
      max-width: 300px;
    }
  }
`}
`;
