import { css } from 'styled-components';
import { height, width, space, typography } from 'styled-system';

const ButtonSizes = {
  small: css`
    padding: 8px 15px;
    font-size: 12px;
    line-height: 13px;
  `,
  medium: css`
    padding: 12px 15px;
    font-size: 13px;
    line-height: 15px;
    &.icon {
      padding: 12px 31px 12px 27px;
    }
  `,
  large: css`
    padding: 17px 22px;
    font-size: 15px;
    line-height: 17px;
  `,
};

const ButtonVariants = {
  primary: css`
    background-color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  `,
  secondary: css`
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.opal};
  `,
  dark: css`
    background-color: ${({ theme }) => theme.colors.blue[40]};
  `,
  dutchiePay: css`
    background-color: ${({ theme }) => theme.colors.dutchiePayGreen};
  `,
  purple: css`
    background-color: ${({ theme }) => theme.colors.purple[35]};
  `,
  green: css`
    background-color: ${({ theme }) => theme.colors.lime[30]};
  `,
  flora: css`
    background-color: ${({ theme }) => theme.colors.flora};
  `,
  opal: css`
    background-color: ${({ theme }) => theme.colors.opal};
  `,
  outline: css`
    border: ${({ theme }) => `2px solid ${theme.colors.opal}`};
    color: ${({ theme }) => theme.colors.opal};
    background: ${({ theme }) => theme.colors.white};
  `,
  outlineBlack: css`
    border: ${({ theme }) => `2px solid ${theme.colors.black}`};
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.white};
  `,
  outlinePrimary: css`
    border: ${({ theme }) => `2px solid ${theme.customized.colors.buttonsLinks}`};
    color: ${({ theme }) => theme.customized.colors.buttonsLinks};
    background: ${({ theme }) => theme.colors.white};
  `,
};

const DisabledVariants = {
  default: css`
    background: ${({ theme }) => theme.colors.blueGrey[80]};
  `,
};

const buttonStyles = css`
  white-space: nowrap;
  position: relative;
  height: fit-content;
  border-radius: 27.5px;
  font-weight: bold;
  text-transform: uppercase;
  appearance: none;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.white};
  ${(props) => ButtonSizes[props.size]};
  ${(props) => (props.disabled ? DisabledVariants.default : ButtonVariants[props.variant])};
  ${({ fluid }) => fluid && `width: 100%`};

  ${(props) =>
    !props.disabled &&
    css`
      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          background-image: ${({ noDim }) => (noDim ? `none` : `linear-gradient(rgba(0, 0, 0, 0.15) 0 0)`)};
        }
      }
      &:disabled {
        background-image: none;
      }
    `}

  svg {
    margin-right: 13px;
    vertical-align: middle;
  }

  ${height}
  ${width}
  ${space}
  ${typography}
`;

export default buttonStyles;
