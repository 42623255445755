import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import useUI from 'src/hooks/use-ui';
import SvgGoogleLogo from 'src/assets/google-logo';

type GoogleLoginProps = {
  redirectUrl: string;
  autoLogin?: boolean;
  onSubmit?: () => void;
  className?: string;
};

export const GoogleLogin = ({ redirectUrl, autoLogin = false, onSubmit, className }: GoogleLoginProps): JSX.Element => {
  const formRef = useRef<HTMLFormElement>(null);
  const { isEmbedded } = useUI();

  useEffect(() => {
    if (autoLogin && formRef.current) {
      formRef.current.submit();
    }
  }, [autoLogin]);

  const handleEmbeddedMenuGoogleLogin = (): void => {
    onSubmit?.();

    window.open(
      `/oauth/auto-login?provider=google&redirectUrl=${redirectUrl}`,
      '_blank',
      'width=400,height=600,toolbar=0,location=0,menubar=0'
    );
  };

  // For the initial embedded menu button, we need to open a new popup window instead of submitting form
  if (isEmbedded && !autoLogin) {
    return (
      <Button type='button' onClick={handleEmbeddedMenuGoogleLogin} className={className}>
        <SvgGoogleLogo height={20} width={20} />
        <span>Continue with Google</span>
      </Button>
    );
  }

  return (
    <>
      <form method='post' action='/api/v2/auth/google' ref={formRef} onSubmit={onSubmit} className={className}>
        <input type='hidden' name='redirect_url' value={redirectUrl} />

        {!autoLogin && (
          <Button type='submit'>
            <SvgGoogleLogo height={20} width={20} />
            <span>Continue with Google</span>
          </Button>
        )}
      </form>
    </>
  );
};

const Button = styled.button`
  align-items: center;
  appearance: none;
  background: #fff;
  border-radius: 50px;
  border: 1px solid #747775;
  color: #1f1f1f;
  cursor: pointer;
  display: flex;
  font: 500 14px / ${20 / 14} 'Roboto', 'Matter', arial, sans-serif;
  gap: 8px;
  justify-content: center;
  padding: 14px;
  transition: all 0.3s ease;
  width: 100%;

  &:hover {
    background: #30303014;
    box-shadow: 0 1px 2px 0 #3c40434d, 0px 1px 3px 1px #3c404326;
  }
`;
