export const fontSizeTokens = {
  'font-size-10': '10px',
  'font-size-20': '11px',
  'font-size-30': '12px',
  'font-size-40': '13px',
  'font-size-50': '14px',
  'font-size-60': '16px',
  'font-size-70': '18px',
  'font-size-80': '20px',
  'font-size-90': '24px',
  'font-size-100': '26px',
  'font-size-110': '32px',
  'font-size-120': '36px',
  'font-size-130': '40px',
  'font-size-140': '46px',
  'font-size-150': '52px',
  'font-size-160': '58px',
  'font-size-170': '66px',
  'font-size-180': '74px',
  'font-size-190': '83px',
};

export const fontWeightTokens = {
  light: 300,
  regular: 400,
  medium: 500,
  semi: 600,
  bold: 700,
};
