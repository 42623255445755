import styled, { css } from 'styled-components';
import { mediaSizes } from 'theme';

import { Button, GreyCapsText, TextButton } from 'components/core';
import CartPriceMinimum from '../price-minimum';

export const Container = styled.div`
  ${({ isEmbedded, isMobile, height, offset, parentOffset, theme: { colors } }) => css`
    background: ${colors.white};
    display: flex;
    flex-direction: column;
    height: ${isEmbedded ? `${height - offset}px` : ` ${height}px`};
    overflow: hidden;
    width: ${isMobile ? '100vw' : '547px'};

    ${isEmbedded &&
    `
      position: relative;
      top: ${parentOffset + offset}px;
    `}
  `}
`;

export const ShoppingText = styled.div`
  overflow: hidden;
`;

export const MobileCheckoutButtonContainer = styled.div`
  display: flex;
  padding: 13px 25px;
  background: ${({ theme }) => theme.colors.white};
  flex: 0 0 326px;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: ${({ isEmbedded }) => isEmbedded && 'wrap'};
`;

export const CheckoutButton = styled(Button)`
  padding: 17px 0px;
  font-size: 13px;
  background: transparent;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  text-transform: none;
  flex-grow: 0;
  flex-shrink: 0;

  &.mobile-header {
    background: ${({ theme }) => theme.customized.colors.buttonsLinks};
    color: ${({ theme }) => theme.colors.white};
    margin: 0 auto;
    width: 100%;
    padding: 13px;
    text-transform: uppercase;
  }

  @media (min-width: 600px) {
    font-size: 15px;
    background: ${({ theme }) => theme.customized.colors.buttonsLinks};
    color: ${({ theme }) => theme.colors.white};
    padding-left: 22px;
    padding-right: 22px;
    text-transform: uppercase;
    order: ${({ isEmbedded }) => isEmbedded && 3};
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  position: relative;
  flex: 0 0 72px;

  p {
    font-size: 13px;
    line-height: 165%;
  }

  @media (min-width: 600px) {
    padding: 0 30px;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.0579928);
  }

  @media (max-width: ${mediaSizes.phone}px) {
    box-shadow: 0px 2.05031px 1.36687px rgba(0, 0, 0, 0.0579928);
    flex: 0 0 64px;
  }
`;

export const ShoppingCartHeader = styled.h1`
  font-size: 16px;
`;

export const HeaderSubtotal = styled.p`
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
`;

export const CapsText = styled(GreyCapsText)`
  font-size: 10px;

  @media (min-width: 600px) {
    font-size: 11px;
  }
`;

export const OrderTypeSpan = styled.span`
  color: ${({ theme }) => theme.colors.grey[30]} !important;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 13px !important;

  @media (min-width: 600px) {
    font-size: 14px !important;
  }
`;

export const DispensaryName = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 21px;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.grey[30]};
  line-height: 120%;
  font-weight: bold;

  @media (min-width: 600px) {
    font-size: 16px;
  }

  @media (max-width: ${mediaSizes.phone}px) {
    font-size: 13px;
    padding-top: 1px;
    max-width: 151px;
  }
`;

export const CloseButton = styled.button`
  align-items: center;
  border: 2px solid ${({ theme }) => theme.customized.colors.buttonsLinks};
  border-radius: 7px;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  height: 42px;
  justify-content: center;
  width: 89px;
  padding: 0;

  svg {
    margin-right: -4px;
    margin-left: -3px;
  }

  @media (max-width: ${mediaSizes.phone}px) {
    height: 34px;
    width: 75px;
    font-size: 14px;
  }
`;

export const DetailContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primaryGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
  display: flex;
  justify-content: space-between;
  font-size: 13px;

  @media (max-width: ${mediaSizes.phone}px) {
    border-bottom: none;
    font-size: 14px;
  }
`;

export const DispensaryHeaderLink = styled.a`
  min-width: 0;
`;

export const Dispensary = styled.div`
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.colors.primaryBlack};
  height: 89px;
  padding-left: 25px;
  flex-grow: 1;

  @media (min-width: 600px) {
    padding-left: 30px;
  }

  @media (max-width: ${mediaSizes.phone}px) {
    height: 81px;
  }
`;

export const DispensaryImage = styled.img`
  height: 49px;
  margin-right: 21px;
  @media (max-width: ${mediaSizes.phone}px) {
    height: 41px;
    margin-right: 15px;
  }
`;

export const DropdownContainer = styled.div`
  align-items: center;
  display: flex;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  position: relative;

  button {
    padding-right: 0;
  }
`;

export const EstimateText = styled.span`
  white-space: nowrap;
`;

export const OrderType = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-right: 25px;
  flex-basis: 180px;
  flex-shrink: 0;

  > div {
    align-items: center;
    display: flex;
    margin: ${({ showEstimate }) => (showEstimate ? '3px 0 6px 0' : '3px 0 3px 0')};

    span {
      color: ${({ theme }) => theme.colors.primaryBlack};
      font-size: 14px;
      font-weight: bold;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.grey[45]};
    font-size: 12px;
    text-align: right;

    span {
      font-weight: bold;

      @media (max-width: ${mediaSizes.phone}px) {
        display: block;
        font-weight: normal;
        font-size: 12px;
      }
    }
  }

  @media (max-width: ${mediaSizes.phone}px) {
    flex-basis: 60px;
  }

  @media (min-width: 600px) {
    padding-right: 30px;
  }
`;

export const ProductsContainer = styled.div`
  background-color: ${({ isDutchiePayEnabledForDispo }) =>
    isDutchiePayEnabledForDispo ? ({ theme }) => theme.colors.white : ({ theme }) => theme.colors.primaryGrey};
  overflow: hidden;
  overflow-y: auto;
  flex-grow: 1;
  width: 100%;
`;

export const CartItems = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: 7px;
`;

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
  width: 100%;
`;

export const Footer = styled.div`
  position: relative;
  height: 106px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 25px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  bottom: 0px;
  max-width: 547px;
  flex: 1 0 106px;

  @media (min-width: 600px) {
    padding: 0 30px;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.087993);
    flex-wrap: ${({ isEmbedded }) => isEmbedded && 'wrap'};
    height: ${({ isEmbedded }) => isEmbedded && '129px'};
  }
`;

export const ContinueShoppingButton = styled(TextButton)`
  font-weight: 700;

  @media (max-width: 959px) {
    margin-top: 16px;
  }

  @media (min-width: 960px) {
    align-items: center;
    display: flex;
    padding: 15px 0 0;
    width: 100%;

    svg {
      margin-right: 4px;
      transform: rotate(90deg);
    }
  }
`;

export const FooterSubtotal = styled.div`
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: ${({ isEmbedded }) => !isEmbedded && 'flex-end'};
  justify-content: center;
  padding-left: ${({ isEmbedded }) => !isEmbedded && '50px'};
  max-width: ${({ isEmbedded }) => isEmbedded && '50%'};

  p {
    color: ${({ theme }) => theme.colors.grey[35]};
    font-size: 13px;
    display: inline;
  }
`;

export const Subtotal = styled.div`
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-weight: bold;
  font-size: 14px;

  strong {
    color: ${({ theme }) => theme.colors.primaryBlack};
    display: inline;
    font-weight: bold;
  }

  @media (max-width: ${mediaSizes.phone}px) {
    font-weight: normal;
    font-size: 15px;
  }
`;

export const Taxes = styled.p`
  color: ${({ theme }) => theme.colors.grey[35]};
  flex-basis: 100%;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 14px;
  margin-top: 4px;
  text-align: ${({ isEmbedded }) => !isEmbedded && 'right'};
`;

export const StyledCartPriceMinimum = styled(CartPriceMinimum)`
  background: ${({ theme }) => theme.colors.darkBackground};
  padding: ${({ isMobile }) => (isMobile ? '8px 25px' : '10px 30px')};
  font-size: ${({ isMobile }) => (isMobile ? '12px' : '14px')};
  line-height: 175%;
`;
