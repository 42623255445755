import { ApolloLink } from 'apollo-link';

import { isBrowser } from 'utils/misc-utils';
import { Session } from './session';

export const apolloSessionLink = new ApolloLink((operation, forward) => {
  if (!isBrowser()) {
    return forward(operation);
  }

  const session = Session.getInstance();

  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      // NOTE: defaulting to emptry string because we get null when session key not found in local-storage
      'x-dutchie-session': session.state ?? '',
    },
  }));

  return forward(operation);
});
