import { useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GqlDispensaries, GqlWebCustomizationSettings, GqlColorSettings, Maybe } from 'types/graphql';
import { fonts as availableFonts } from 'shared/constants/menu-fonts';
import { navigationBarColors, navigationBarColorsV2, linkColors, linkColorsV2 } from 'shared/constants/menu-colors';
import { mergeWithoutNull } from 'src/utils/misc-utils';

export type Color = {
  background: string;
  border: string;
  color: string;
  key: string;
};

type WebCustomizations = {
  colors: {
    navBar: string | null;
    buttonsLinks: string | null;
  };
  fonts: {
    primary: string | null;
    secondary: string | null;
  };
};

type UseWebCustomizationSettingsReturn = () => WebCustomizations;

const getFallbackCustomization = (
  webCustomizationSettings: Maybe<GqlWebCustomizationSettings> | undefined,
  legacyColorSettings: Maybe<GqlColorSettings> | undefined
): WebCustomizations => {
  if (webCustomizationSettings) {
    const { linkColor, navBarColor } = webCustomizationSettings.colorSettings;
    const { family } = webCustomizationSettings.fontSettings;

    return {
      colors: {
        navBar: navigationBarColorsV2[navBarColor].background,
        buttonsLinks: linkColorsV2[linkColor].background,
      },
      fonts: {
        primary: availableFonts[family].fontFamily,
        secondary: availableFonts[family].fontFamily,
      },
    };
  }

  const { linkColor, navBarColor } = legacyColorSettings ?? {};

  return {
    colors: {
      navBar: navigationBarColors.find(({ key }) => key === navBarColor)?.background ?? null,
      buttonsLinks: linkColors.find(({ key }) => key === linkColor)?.background ?? null,
    },
    fonts: {
      primary: null,
      secondary: null,
    },
  };
};

export function useGetWebCustomizationSettings(
  dispensary: GqlDispensaries | undefined
): UseWebCustomizationSettingsReturn {
  const flags = useFlags();
  const isCustomizationV2Enabled = flags['growth.ecomm.menu-customization-v2.rollout'] ?? false;

  return useCallback(() => {
    const webCustomizationSettingsV2 = dispensary?.webCustomizationSettingsV2;

    const fallbackCustomization = getFallbackCustomization(
      dispensary?.webCustomizationSettings,
      dispensary?.colorSettings
    );

    if (webCustomizationSettingsV2 && isCustomizationV2Enabled) {
      const { colors, fonts } = webCustomizationSettingsV2;

      const customization = {
        colors: {
          navBar: colors?.navBar,
          buttonsLinks: colors?.buttonsLinks,
        },
        fonts: {
          primary: fonts?.heading,
          secondary: fonts?.body,
        },
      };

      return mergeWithoutNull(fallbackCustomization, customization);
    }

    return fallbackCustomization;
  }, [
    dispensary?.colorSettings,
    dispensary?.webCustomizationSettings,
    dispensary?.webCustomizationSettingsV2,
    isCustomizationV2Enabled,
  ]);
}

export const getFullFontFamily = (font: string | null): string | null => {
  if (!font) {
    return null;
  }

  const fontOption =
    Object.values(availableFonts).find((option) => option.fontFamily === font) ?? availableFonts.MATTER;

  return [`'${fontOption.fontFamily}'`, ...fontOption.fallbacks].join(', ');
};

export const getEncodedFonts = (selectedFonts: Array<string>): string => {
  const nonLocalFonts = Object.values(availableFonts).filter((font) => !font.localFont);

  const filteredFonts = new Set(
    nonLocalFonts.filter(
      (fontOption) => Object.values(selectedFonts).includes(fontOption.fontFamily) && fontOption.fontFamilyEncoded
    )
  );

  return Array.from(filteredFonts)
    .map((fontOption) => `family=${String(fontOption.fontFamilyEncoded)}`)
    .join('&');
};
