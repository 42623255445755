import styled from 'styled-components';

export const fullWidthStyles = `
  @media (min-width: 1320px) {
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
  }
`;

export const CarouselContainer = styled.div`
  --fade-to-color: var(--carousel-gradient-color, ${({ backgroundColor }) => backgroundColor});
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-color: ${({ borderColor }) => `${borderColor ? String(borderColor) : `#E3E7E9`}`};
  border-width: ${({ border }) => (border ? '1px' : '0px')};
  border-style: solid;
  border-radius: 12px;
  overflow: hidden;
  margin: ${({ margin }) => (margin ? `${String(margin)}px auto` : '0 auto')};

  ${({ fullWidth }) =>
    fullWidth &&
    `
      @media (min-width: 600px) {
        max-width: 534.5px;
      }
      @media (min-width: 750.5px) {
        max-width: 750.5px;
      }
      @media (min-width: 966.5px) {
        max-width: 966.5px;
      }
      @media (min-width: 1187px) {
        max-width: 1187px;
      }
    `}
`;

export const Content = styled.div`
  padding-top: ${({ noHeader }) => (noHeader ? '0' : '20px')};
  padding-left: ${({ noHeader }) => (noHeader ? '20px' : '0')};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-left: 0;
  }
`;
