import styled, { css } from 'styled-components';
import { layout } from 'styled-system';

import { Select, TextInput, PlacesAutocompleteInput } from 'components/core';

export const baseInputStyles = css`
  background: ${({ theme }) => theme.colors.primaryGrey};
  border: ${({ theme }) => `1px solid ${theme.colors.grey[80]}`} !important;
  color: ${({ theme }) => theme.colors.grey[30]} !important;
  border-radius: 6px !important;
  height: 55px;

  & > input {
    ${({ label, $addLabelOffset }) => ($addLabelOffset && label ? `padding-top: 30px` : '')};
    padding-left: 25px;
    font-size: 16px;
    font-weight: bold;
    &.MuiInputBase-input {
      color: ${({ theme, value }) => value && theme.colors.grey[30]} !important;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.75;
    }
  }

  &.Mui-focused {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.blue[55]} !important;
    box-shadow: ${({ theme }) => `0px 1px 5px ${theme.colors.blueShadow}`};
  }

  &.empty:not(.Mui-focused) {
    color: transparent !important;
  }

  &.Mui-error {
    border: 1px solid ${({ theme }) => theme.colors.red[60]} !important;
    &.MuiFormLabel-root {
      color: ${({ theme }) => `${theme.colors.red[60]} !important`};
    }
  }

  input:-webkit-autofill {
    border-color: ${({ theme }) => theme.colors.blue[55]} !important;
    padding-top: 23px;
    padding-bottom: 11px;
  }
`;

export const StyledTextInput = styled(TextInput)`
  ${baseInputStyles}

  & > input {
    font-weight: 700;
    padding-top: 23px;
  }

  ${layout}

  ${({ hideFieldset }) =>
    hideFieldset &&
    css`
      & > fieldset {
        display: none;
      }
    `}
`;

export const StyledPlacesAutocompleteInput = styled(PlacesAutocompleteInput)`
  ${baseInputStyles}

  & > input {
    padding-top: 36px;
  }
`;

export const StyledSelect = styled(Select)`
  ${baseInputStyles};

  & .MuiSelect-select {
    justify-content: flex-start;
    padding-left: 25px;
  }

  ${layout}
`;
