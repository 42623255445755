import React from 'react';
import styled from 'styled-components';

import SvgAddIcon from 'assets/add-icon';
import { CircleButton } from 'components/core/button';

import { CarouselTheme } from 'src/components/hybrid-carousel';

type QuickAddButtonProps = {
  carouselTheme?: CarouselTheme;
  filled?: boolean;
  productName: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const QuickAddButton = ({
  carouselTheme,
  filled = false,
  onClick,
  productName,
  ...props
}: QuickAddButtonProps): JSX.Element => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    e.stopPropagation();
    onClick(e);
  };

  return (
    <StyledCircleButton
      onClick={handleClick}
      aria-label={`Add ${productName} to cart`}
      data-testid='quick-add-button'
      filled={filled}
      {...carouselTheme}
      {...props}
    >
      <SvgAddIcon />
    </StyledCircleButton>
  );
};

const StyledCircleButton = styled(CircleButton)`
  color: ${({ theme }) => theme.colors.white};
  padding: 0; // padding was being added from webkit, causing the icon to be smaller on iOS so we need to explicitly set it ourselves.
  position: absolute;
  right: 10px;
  top: 10px;
  transition: all 0.2s;
  z-index: 9;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    ${({ filled }) => (filled ? '' : 'top: 10px;')}
  }

  ${({ filled }) =>
    filled &&
    `
    & > svg {
      width: 13px;
      height: 13px;
      transition: all 0.2s;
    }
  `}

  ${({ filled, primaryColor, primaryColorHover }) =>
    filled &&
    `
    background-color: ${String(primaryColor)};
    & > svg > path {
      fill: currentColor;
    }
    &:hover {
      background-color: ${String(primaryColorHover ?? primaryColor)};
    }
  `}
`;
