import styled, { css } from 'styled-components';
import { space } from 'styled-system';

import { smallCapsSubtext } from 'components/core/typography';
import { focusStyles } from 'src/theme';

import { ContentWrapper } from 'components/core';

export const CardCarouselItem = styled.div`
  &:last-of-type {
    padding-right: 14px;
  }

  .focus-visible {
    ${focusStyles}
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    a > div:hover {
      box-shadow: none;
    }
  }
`;

export const Section = styled(ContentWrapper)`
  padding: 32px 0 32px;
  position: relative;
  ${space};
  border-bottom: ${({ noBorder, theme }) => `${noBorder ? `0` : `1px`} solid ${theme.colors.blueGrey[90]}`};
  max-width: 534.5px;
  margin: 0 auto;
  z-index: 10; /* ensure that our swipeable drawer doesn't snip this off */

  @media (min-width: 750.5px) {
    max-width: 750.5px;
  }

  @media (min-width: 966.5px) {
    max-width: 966.5px;
  }

  @media (min-width: 1187px) {
    max-width: 1187px;
  }
`;

export const GroupHeader = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: space-between;
`;

export const Heading = styled.h2`
  font-weight: bold;
  line-height: 120%;
  font-size: 26px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 20px;
    line-height: 29px;
  }
`;

export const SubHeading = styled.h2`
  ${smallCapsSubtext};
  color: ${({ theme }) => theme.colors.grey[70]};
  margin-bottom: 20px;
`;

export const anchorStyles = css`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
`;

export const StyledAnchor = styled.a`
  ${anchorStyles}
`;
