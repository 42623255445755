import _ from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import Big, { Big as BigType } from 'big.js';
import {
  ConsumerInput,
  ConsumerType,
  DiscountGlobalConfig,
  DiscountOutputCollection,
  ManualFeeInput,
} from '@dutchie/cart-calculator';

import { mapWeight } from 'shared/helpers/specials/schema-mapper/other-mappers';

import {
  DetailItem,
  EcommProductDiscount,
  InnerProduct,
  ItemTag,
  MapperLineItem,
  MapperOrderInput,
  OrderInputArgs,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  POSMetaData,
  ProductsSpecialDiscountAmounts,
} from './helpers.types';

export const getAdjustedBasePrice = ({ basePrice, mixAndMatch }: Partial<DetailItem>): BigType =>
  Big(mixAndMatch?.adjustedBasePrice ?? basePrice ?? 0);

const getConsumer = (): ConsumerInput => {
  const firstTimer = false;
  const groupIds: string[] = [];
  const historicalDiscountIds: string[] = [];

  return {
    firstTimer,
    groupIds,
    historicalDiscountIds,
  };
};

const getConsumerType = ({ medicalOrder }: OrderInputArgs): ConsumerType =>
  medicalOrder ? { id: `medical`, isMedical: true } : { id: `recreational`, isMedical: false };

const getCouponCodes = ({ coupon }: OrderInputArgs): string[] => {
  const couponCodes: string[] = [];
  if (coupon?.code) {
    couponCodes.push(coupon.code);
  }
  return couponCodes;
};

const getDestinationZoneIds = (): string[] => [];

const getIsCurbsidePickup = ({ deliveryOption }: OrderInputArgs): boolean => deliveryOption === 'curbsidePickup';

/* eslint-disable @typescript-eslint/naming-convention */
export const getLineItems = ({
  detailsSorted,
  directPOSProductIdMapping,
  useActiveBatchTagOfWeightOption,
  discountTaxOrder = `both`,
  products,
  rawPOSWeightMapping,
}: OrderInputArgs): MapperLineItem[] => {
  let lineItemIndex = 0;
  return _.flatMap(detailsSorted, (detailItem) => {
    const { bottleDepositTaxCents, key: productKey, wholesalePrice } = detailItem;
    const { option: weightOption, rawOption, product: innerProduct = {}, quantity } = products[productKey];
    // Attempt to find the rawWeightOption if not already set on the product root
    const rawWeightOption = rawOption ?? innerProduct.rawOptions?.[_.indexOf(innerProduct.Options, weightOption)];

    const weight =
      rawPOSWeightMapping && !_.isNil(rawWeightOption) ? mapWeight(rawWeightOption) : mapWeight(weightOption);

    let price = getAdjustedBasePrice(detailItem).times(100);

    if (discountTaxOrder === 'taxesFirst') {
      const cannabisTaxCents = detailItem.cannabisTax(price, wholesalePrice, innerProduct, weightOption);
      const salesTaxCents = detailItem.salesTax(price, wholesalePrice, innerProduct, weightOption);
      price = price.add(bottleDepositTaxCents).add(cannabisTaxCents).add(salesTaxCents);
    }

    return _.times(quantity, () => {
      lineItemIndex += 1;
      return {
        appliedManualDiscounts: [],
        ...(innerProduct.brandId ? { brandId: innerProduct.brandId } : {}),
        categoryId: innerProduct.categoryId ?? innerProduct.type ?? '',
        // this needs to be in cents
        cost: +price,
        ecommProductKey: productKey,
        id: `line-item-${lineItemIndex}`,
        isCannabis: true, // since we don't know, we'll just default to everything true
        // we'll want this to include taxes
        price: +price,
        productId: innerProduct._id ?? innerProduct.id ?? '',
        // TODO: Shouldn't this be mapping innerProduct.POSMetaData?.canonicalSKU?
        sku: innerProduct.POSMetaData?.canonicalID ?? '',
        ...(innerProduct.subcategory ? { subCategoryId: innerProduct.subcategory } : {}),
        ...(weight ? { weight } : {}),
        POSMetaData: getPOSMetaData(
          innerProduct,
          weightOption,
          directPOSProductIdMapping,
          useActiveBatchTagOfWeightOption
        ),
        ...(innerProduct.POSMetaData?.canonicalStrainId
          ? { strainId: innerProduct.POSMetaData.canonicalStrainId }
          : {}),
        ...(innerProduct.POSMetaData?.canonicalVendorId
          ? { vendorId: innerProduct.POSMetaData.canonicalVendorId }
          : {}),
      };
    });
  });
};

const getActiveBatchTags = (
  innerProduct: InnerProduct,
  weightOption: string | undefined,
  useActiveBatchTagOfWeightOption = false
): ItemTag[] => {
  if (!useActiveBatchTagOfWeightOption) {
    // deprecated way
    return innerProduct.POSMetaData?.activeBatchTags ?? [];
  }
  // Bulk Weight products often have a set children attrbiute which includes the various weight option available which which is where the tags specific to the weight option are stored
  // In the event it's a quantity based product the tags are stored directly on the POSMetaData object on the root level
  const metaData = innerProduct.POSMetaData ?? {};
  const { children } = metaData;
  if ((children?.length ?? 0) > 0) {
    const option = children?.find((child) => child.option === weightOption);
    return option?.activeBatchTags ?? [];
  }
  return metaData.activeBatchTags ?? [];
};

const getPOSMetaData = (
  innerProduct: InnerProduct,
  weightOption: string | undefined,
  directPOSProductIdMapping: boolean | undefined,
  useActiveBatchTagOfWeightOption: boolean | undefined
): POSMetaData => {
  const canonicalID = getCanonicalID(innerProduct.POSMetaData, weightOption, directPOSProductIdMapping);
  const activeBatchTags = getActiveBatchTags(innerProduct, weightOption, useActiveBatchTagOfWeightOption);

  return {
    ...(innerProduct.POSMetaData?.canonicalCategoryId
      ? { canonicalCategoryId: innerProduct.POSMetaData.canonicalCategoryId }
      : {}),
    ...(innerProduct.POSMetaData?.canonicalBrandId
      ? { canonicalBrandId: innerProduct.POSMetaData.canonicalBrandId }
      : {}),
    ...(innerProduct.POSMetaData?.canonicalStrainId
      ? { canonicalStrainId: innerProduct.POSMetaData.canonicalStrainId }
      : {}),
    ...(innerProduct.POSMetaData?.canonicalVendorId
      ? { canonicalVendorId: innerProduct.POSMetaData.canonicalVendorId }
      : {}),
    ...(activeBatchTags.length > 0 ? { activeBatchTags } : {}),
    ...(innerProduct.POSMetaData?.canonicalProductTags
      ? { productTags: innerProduct.POSMetaData.canonicalProductTags }
      : {}),
    ...(canonicalID ? { canonicalID } : {}),
  };
};

const getCanonicalID = (
  metaData: POSMetaData | undefined,
  weightOption: string | undefined,
  directPOSProductIdMapping: boolean | undefined
): string | undefined => {
  if (directPOSProductIdMapping) {
    const childId = metaData?.children?.find((child) => child.option === weightOption)?.canonicalID;
    return childId ?? metaData?.canonicalID;
  }
  return metaData?.canonicalID;
};
/* eslint-enable @typescript-eslint/naming-convention */

const getManualFees = (): ManualFeeInput[] => [];

export const mapOrderInput = (args: OrderInputArgs): MapperOrderInput => {
  const consumer = getConsumer();
  const consumerType = getConsumerType(args);
  const couponCodes = getCouponCodes(args);
  const destinationZoneIds = getDestinationZoneIds();
  const isCurbsidePickup = getIsCurbsidePickup(args);
  const lineItems = getLineItems(args);
  const manualFees = getManualFees();

  return {
    consumer,
    consumerType,
    couponCodes,
    destinationZoneIds,
    isCurbsidePickup,
    lineItems,
    manualFees,
  };
};

export const mapDiscountGlobalConfig = (timezone = `America/Los_Angeles`): DiscountGlobalConfig => ({
  discountBehavior: 'FAVOR_CONSUMER',
  // default values for unsupported fields right now
  discountOverPrice: false,
  noBelowCostDiscount: false,
  noFreeCannabisDiscount: false,
  roundPercentDiscounts: false,
  timezone,
});

/**
 * Calculates the discounts per special per ecomm line item.
 * @param lineItems - POS line items
 * @param outputCollection - discount amounts per special per POS line item.
 */
export const mapPOSDiscountCalcResults = (
  lineItems: Pick<MapperLineItem, 'ecommProductKey' | 'id'>[],
  outputCollection: DiscountOutputCollection[]
): ProductsSpecialDiscountAmounts =>
  lineItems.reduce(
    (
      productHash: ProductsSpecialDiscountAmounts,
      currLineItem: Pick<MapperLineItem, 'ecommProductKey' | 'id'>
    ): ProductsSpecialDiscountAmounts => {
      const { id: lineItemId, ecommProductKey } = currLineItem;

      if (!ecommProductKey) {
        console.error(`Could not find ecomm product key.`);
        return productHash;
      }

      const { outputs: discountOutputs = [] } =
        outputCollection.find(({ lineItemId: outputLineItemId }) => outputLineItemId === lineItemId) ?? {};
      const productDiscounts: Record<string, EcommProductDiscount> =
        ecommProductKey in productHash ? productHash[ecommProductKey].discountAmountsBySpecial : {};

      // add discounts for this line items to special discount totals
      discountOutputs.forEach(({ amount, discountId }) => {
        const specialToUpdate =
          discountId in productDiscounts
            ? productDiscounts[discountId]
            : {
                amount: Big(0),
                quantityApplied: 0,
                specialId: discountId,
              };

        specialToUpdate.amount = specialToUpdate.amount.add(Big(amount));
        specialToUpdate.quantityApplied += 1;
        productDiscounts[discountId] = specialToUpdate;
      });

      // example of ecommProductKey: 'a2h2d58734697jj72d8793j_3g' (basically, 'product_id_product_weight')
      return {
        ...productHash,
        [ecommProductKey]: {
          discountAmountsBySpecial: productDiscounts,
        },
      };
    },
    {}
  );
