import { useFlags } from 'launchdarkly-react-client-sdk';
import { AdInventoryId } from 'types/graphql';
import { Product } from 'src/utils/helpers/product';
import { Category } from 'src/generics/data/generic-category/data.types';

export const ADS_FETCH_POLICY = 'cache-and-network';
export function getMostCommonBrandId(products: Product[]): string {
  const result = products.reduce(
    (acc, product) => {
      const brandId = product.brandId ?? product.brand.id;

      if (brandId) {
        acc.counts[brandId] = (acc.counts[brandId] || 0) + 1;

        if (acc.counts[brandId] > acc.maxCount) {
          acc.maxCount = acc.counts[brandId];
          acc.mostCommonBrandId = brandId;
        }
      }
      return acc;
    },
    { counts: {} as Record<string, number>, maxCount: 0, mostCommonBrandId: '' }
  );

  return result.mostCommonBrandId;
}
export function useAdInventoryId(dispensaryId: string, category?: Category | null): AdInventoryId {
  const { categoryCarouselAuc, homeCarouselAuc } = AdInventoryId;

  if (category) {
    return categoryCarouselAuc;
  }

  return homeCarouselAuc;
}

export function participatingInAds(flags: Record<string, unknown>): boolean {
  return (
    flags['growth.ads.sponsored-brand-carousel.placement'] !== 'none' ||
    flags['growth.ads.personalization-carousel.placement'] !== 'none'
  );
}

export function useParticipatingInAds(): boolean {
  const flags = useFlags();
  return participatingInAds(flags);
}
