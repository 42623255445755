import React from 'react';
import styled from 'styled-components';
import { Link, EmbeddedExternalLink } from 'components/core';
import { Typography } from 'src/typography';
import { GroupHeader, StyledAnchor, anchorStyles } from '../carousels.styles';

const StyledEmbeddedExternalLink = styled(EmbeddedExternalLink)`
  ${anchorStyles}
`;

const LinkComponent = ({ children, href, isEmbeddedCarousel, query, route }) =>
  isEmbeddedCarousel ? (
    <StyledEmbeddedExternalLink href={href}>{children}</StyledEmbeddedExternalLink>
  ) : (
    <Link href={href} route={route} query={query}>
      <StyledAnchor href={href}>{children}</StyledAnchor>
    </Link>
  );

export const CarouselHeader = ({
  href,
  isEmbeddedCarousel,
  label,
  labelId,
  linkLabel = '',
  query,
  route,
  showLink,
  showTitle,
  t,
}) => {
  const title = showTitle && t(`dispensary.section.${label.replace(/ /g, '')}`, `${label}`);
  return (
    <GroupHeader>
      <Typography.Heading tag='h2' size='medium' id={labelId}>
        {title}
      </Typography.Heading>
      {showLink && linkLabel && (
        <LinkComponent href={href} isEmbeddedCarousel={isEmbeddedCarousel} query={query} route={route}>
          {t('common.view_all', 'View All')}
        </LinkComponent>
      )}
    </GroupHeader>
  );
};
