import _set from "lodash/set";
import _map from "lodash/map";
import _flatMap from "lodash/flatMap";
import _sum from "lodash/sum";
import _some from "lodash/some";
// eslint-disable-next-line import/no-extraneous-dependencies
import Big from 'big.js';
import { DiscountCalculator } from '@dutchie/cart-calculator';
import { getAllSpecialsForCart } from 'shared/order/get-all-specials-for-cart';
import { mapDiscountGlobalConfig, mapOrderInput, mapPOSDiscountCalcResults } from './helpers';
import { updateReceiptAndDetails } from './other-helpers';
export var calculateProductDiscountsPOS = function calculateProductDiscountsPOS(args) {
  var _window;

  var avoidTaxes = args.avoidTaxes,
      bogoCalcsData = args.bogoCalcsData,
      bogoRewardsApplied = args.bogoRewardsApplied,
      dispensary = args.dispensary,
      bottleDepositTaxCentsTotalArg = args.bottleDepositTaxCentsTotal,
      cannabisTaxTotalArg = args.cannabisTaxTotal,
      coupon = args.coupon,
      couponApplicableSubtotal = args.couponApplicableSubtotal,
      couponApplicableSubtotalPlusTaxes = args.couponApplicableSubtotalPlusTaxes,
      couponLeftOver = args.couponLeftOver,
      creditArg = args.credit,
      detailsSorted = args.detailsSorted,
      discountTaxOrder = args.discountTaxOrder,
      globalSpecialsSettings = args.globalSpecialsSettings,
      medicalOrder = args.medicalOrder,
      products = args.products,
      productTotalArg = args.productTotal,
      receipt = args.receipt,
      residual = args.residual,
      residualPriceArg = args.residualPrice,
      rewardApplicableSubtotal = args.rewardApplicableSubtotal,
      rewardApplicableSubtotalPlusTaxes = args.rewardApplicableSubtotalPlusTaxes,
      rewardAppliedToCredit = args.rewardAppliedToCredit,
      rewardCredit = args.rewardCredit,
      rewardLeftOver = args.rewardLeftOver,
      rewards = args.rewards,
      salesTaxTotalArg = args.salesTaxTotal;
  var couponData = {
    coupon: coupon,
    couponApplicableSubtotal: couponApplicableSubtotal,
    couponApplicableSubtotalPlusTaxes: couponApplicableSubtotalPlusTaxes,
    couponLeftOver: couponLeftOver
  };
  var rewardsData = {
    rewardApplicableSubtotal: rewardApplicableSubtotal,
    rewardApplicableSubtotalPlusTaxes: rewardApplicableSubtotalPlusTaxes,
    rewardAppliedToCredit: rewardAppliedToCredit,
    rewardCredit: rewardCredit,
    rewardLeftOver: rewardLeftOver,
    rewards: rewards
  };
  var totals = {
    bottleDepositTaxCentsTotal: Big(bottleDepositTaxCentsTotalArg !== null && bottleDepositTaxCentsTotalArg !== void 0 ? bottleDepositTaxCentsTotalArg : 0),
    cannabisTaxTotal: Big(cannabisTaxTotalArg !== null && cannabisTaxTotalArg !== void 0 ? cannabisTaxTotalArg : 0),
    creditTotal: Big(creditArg !== null && creditArg !== void 0 ? creditArg : 0),
    productTotal: Big(productTotalArg !== null && productTotalArg !== void 0 ? productTotalArg : 0),
    residualPrice: Big(residualPriceArg !== null && residualPriceArg !== void 0 ? residualPriceArg : 0),
    salesTaxTotal: Big(salesTaxTotalArg !== null && salesTaxTotalArg !== void 0 ? salesTaxTotalArg : 0)
  };
  var discountGlobalConfig = mapDiscountGlobalConfig(dispensary.timezone);
  var v4Specials = getAllSpecialsForCart({
    dispensary: dispensary,
    globalSpecialsSettings: globalSpecialsSettings,
    items: detailsSorted,
    medicalOrder: medicalOrder,
    outputSpecialVersion: 4
  });
  args.directPOSProductIdMapping = _some(v4Specials, 'directPOSProductIdMapping');
  args.rawPOSWeightMapping = _some(v4Specials, 'rawPOSWeightMapping');
  args.useActiveBatchTagOfWeightOption = _some(v4Specials, 'useActiveBatchTagOfWeightOption');
  var orderInput = mapOrderInput(args);
  var discountSyncDebugging = {};
  discountSyncDebugging.postConversionV4Specials = v4Specials;
  discountSyncDebugging.orderInput = orderInput;
  var dartCalcsResult;

  try {
    dartCalcsResult = DiscountCalculator.calculate(discountGlobalConfig, v4Specials, orderInput);
  } catch (error) {
    console.error('CART CALCULATOR ERROR: ', error);
    dartCalcsResult = [];
  }

  discountSyncDebugging.discountCalculatorOutput = dartCalcsResult;
  discountSyncDebugging.dartResultsDiscountSum = _sum(_flatMap(dartCalcsResult, function (_ref) {
    var outputs = _ref.outputs;
    return _map(outputs, function (_ref2) {
      var amount = _ref2.amount;
      return amount;
    });
  })); // See helpers.types.ts for the shape of ProductsSpecialDiscountAmounts

  var productsSpecialDiscountAmounts = mapPOSDiscountCalcResults(orderInput.lineItems, dartCalcsResult); // mutates the receipt, details, couponData, rewardsData, and totals

  updateReceiptAndDetails(receipt, detailsSorted, totals, {
    couponData: couponData,
    discountTaxOrder: discountTaxOrder,
    products: products,
    productsSpecialDiscountAmounts: productsSpecialDiscountAmounts,
    rewardsData: rewardsData,
    specials: v4Specials
  });
  var bottleDepositTaxCentsTotal = totals.bottleDepositTaxCentsTotal,
      cannabisTaxTotal = totals.cannabisTaxTotal,
      credit = totals.creditTotal,
      productTotal = totals.productTotal,
      residualPrice = totals.residualPrice,
      salesTaxTotal = totals.salesTaxTotal;

  if (typeof window !== 'undefined' && (_window = window) !== null && _window !== void 0 && _window._____APP_STATE_____) {
    _set(window._____APP_STATE_____, 'debugging.discountSync', discountSyncDebugging);
  }

  return {
    avoidTaxes: avoidTaxes,
    bogoCalcsData: bogoCalcsData,
    bogoRewardsApplied: bogoRewardsApplied,
    bottleDepositTaxCentsTotal: bottleDepositTaxCentsTotal,
    cannabisTaxTotal: cannabisTaxTotal,
    couponLeftOver: couponData.couponLeftOver,
    credit: credit,
    productTotal: productTotal,
    residual: residual,
    residualPrice: residualPrice,
    rewardAppliedToCredit: rewardsData.rewardAppliedToCredit,
    rewardCredit: rewardsData.rewardCredit,
    rewardLeftOver: rewardsData.rewardLeftOver,
    salesTaxTotal: salesTaxTotal
  };
};