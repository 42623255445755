import React from 'react';
import { layout } from 'styled-system';
import styled, { css } from 'styled-components';

import { Clickable } from 'shared/components';

import { useIsMobile } from 'hooks/use-is-mobile';
import { UseProductOptionsStateReturn } from 'hooks/use-product-options-state';
import useDispensary from 'dispensary/hooks/use-dispensary';
import { useHideDiscountFromConsumer } from 'shared/hooks/use-hide-discounts-from-consumer';

type ProductOptionsProps = Pick<UseProductOptionsStateReturn, 'options' | 'selectedOption'> & {
  handleOptionClick: (opt: UseProductOptionsStateReturn['selectedOption']) => void;
};

export function ProductOptions({ handleOptionClick, selectedOption, options }: ProductOptionsProps): JSX.Element {
  const isMobile = useIsMobile();
  const { dispensary } = useDispensary();
  const hideDiscounts = useHideDiscountFromConsumer(dispensary);

  return (
    <Container scrollOptions={isMobile} hasAnySpecials={options.some((item) => !!item.special)}>
      {options.map((opt) => (
        <OptionsContainer key={opt.value}>
          <Option
            aria-checked={opt.value === selectedOption.value}
            hasSpecial={!!opt.special && !hideDiscounts}
            key={opt.label}
            onClick={() => handleOptionClick(opt)}
            role='radio'
            selected={opt.value === selectedOption.value}
          >
            <Label>{opt.label}</Label>

            {opt.special && !hideDiscounts && <StrikedOutPrice>{opt.formattedPrice}</StrikedOutPrice>}

            <BoldSpan>{opt.special ? opt.special.formattedPrice : opt.formattedPrice}</BoldSpan>

            {opt.special && !hideDiscounts && (
              <DiscountLabel selected={opt.value === selectedOption.value}>{opt.formattedDiscount}</DiscountLabel>
            )}
          </Option>
        </OptionsContainer>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin: 15px -25px 20px;
  padding: 0 25px;

  ${layout}
  ${({ theme }) => theme.breakpoints.up(`md`)} {
    margin-top: 18px;
  }

  ${({ hasAnySpecials }) =>
    hasAnySpecials &&
    css`
      padding-bottom: 10px;
    `}

  ${({ scrollOptions }) =>
    scrollOptions
      ? css`
          overflow-x: auto;
          height: 75px;

          ::-webkit-scrollbar {
            display: none;
          }

          -ms-overflow-style: none;
          scrollbar-width: none;
        `
      : css`
          flex-flow: row wrap;
        `}
`;

const OptionsContainer = styled.div`
  margin: 4.5px;
`;

const Option = styled(Clickable)`
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  justify-content: center;
  padding: 0;
  width: 73px;
  min-width: 73px;
  height: 59px;
  line-height: 1.15;
  cursor: ${({ disabled }) => (disabled ? `default` : `pointer`)};

  ${({ theme }) => css`
    border: 1px solid ${theme.colors.blueGrey[80] as string};
    color: ${theme.colors.primaryBlack};
    background-color: ${theme.colors.white};
  `}

  ${({ selected }) =>
    selected &&
    css`
      border: ${({ theme }) => `2px solid ${theme.customized.colors.buttonsLinks as string}`};
      color: ${({ theme }) => theme.customized.colors.buttonsLinks};
    `};

  ${({ hasSpecial }) =>
    hasSpecial &&
    css`
      height: 62px;
      line-height: 1;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

const Label = styled.span`
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
`;

const BoldSpan = styled.span`
  font-weight: bold;
  font-size: 14px;
  margin-top: 5px;
  margin-right: 0;
`;

const StrikedOutPrice = styled.span`
  font-size: 12px;
  margin-top: 2.5px;
  color: ${({ theme }) => theme.colors.grey[70]};
  text-decoration-line: line-through;
`;

const DiscountLabel = styled.div`
  background: ${({ selected, theme }) =>
    selected ? theme.customized.colors.buttonsLinks : theme.colors.red[60]} !important;
  border-radius: 0px 0px 9px 9px;
  bottom: -10px;
  color: #fff;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 11px;
  font-weight: bold;
  line-height: 11px;
  margin-top: -13px;
  padding: 2px 0;
  position: relative;
  text-align: center;
  top: 19px;
  width: inherit;
`;
