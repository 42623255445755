import _extends from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["label", "breakpoint", "category", "imgSrc", "onClick", "children", "disableImageAlt"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { layout } from 'styled-system';
import Card from 'shared/components/card';
import Imgix from 'shared/components/imgix';
export default function CategoryTile(_ref) {
  var label = _ref.label,
      _ref$breakpoint = _ref.breakpoint,
      breakpoint = _ref$breakpoint === void 0 ? "960" : _ref$breakpoint,
      category = _ref.category,
      imgSrc = _ref.imgSrc,
      onClick = _ref.onClick,
      children = _ref.children,
      _ref$disableImageAlt = _ref.disableImageAlt,
      disableImageAlt = _ref$disableImageAlt === void 0 ? false : _ref$disableImageAlt,
      props = _objectWithoutProperties(_ref, _excluded);

  var computedLabel = label || (category === null || category === void 0 ? void 0 : category.label);
  return __jsx(StyledCard, _extends({
    breakpoint: breakpoint,
    alwaysShowShadow: true,
    onClick: onClick
  }, props), children, __jsx(CardHeader, null, __jsx(Text, null, "Shop"), __jsx(Title, {
    breakpoint: breakpoint
  }, computedLabel)), __jsx(ImageContainer, null, __jsx(Image, {
    src: imgSrc,
    htmlAttributes: {
      alt: disableImageAlt ? '' : computedLabel
    }
  })));
}
export var StyledCard = styled(Card).withConfig({
  displayName: "category-tile__StyledCard",
  componentId: "vvhc1p-0"
})(["justify-content:flex-start;padding:15px;border-radius:17px;background:#fff;height:100%;width:100%;", " @media only screen and (min-width:992px){&:hover{box-shadow:0px 5px 14px rgba(0,0,0,0.25);}&:focus{box-shadow:0px 5px 14px rgba(0,0,0,0.25);outline-color:transparent;outline-width:2px;}}@media screen and (min-width:", "px){border-radius:24px;padding:20px 25px;}"], layout, function (_ref2) {
  var breakpoint = _ref2.breakpoint;
  return breakpoint;
});
var Text = styled.p.withConfig({
  displayName: "category-tile__Text",
  componentId: "vvhc1p-1"
})(["color:#afb1b2;display:flex;font-family:", ";font-size:11px;font-weight:bold;line-height:12px;margin:0;text-transform:uppercase;"], function (_ref3) {
  var _theme$customized, _theme$customized$fon;

  var theme = _ref3.theme;
  return theme === null || theme === void 0 ? void 0 : (_theme$customized = theme.customized) === null || _theme$customized === void 0 ? void 0 : (_theme$customized$fon = _theme$customized.fonts) === null || _theme$customized$fon === void 0 ? void 0 : _theme$customized$fon.secondary;
});
var Title = styled.span.withConfig({
  displayName: "category-tile__Title",
  componentId: "vvhc1p-2"
})(["color:#242526;font-weight:bold;font-size:16px;margin:0;display:flex;@media screen and (min-width:", "px){font-size:20px;}"], function (_ref4) {
  var breakpoint = _ref4.breakpoint;
  return breakpoint;
});
var CardHeader = styled.div.withConfig({
  displayName: "category-tile__CardHeader",
  componentId: "vvhc1p-3"
})(["width:100%;"]);
var Image = styled(Imgix).withConfig({
  displayName: "category-tile__Image",
  componentId: "vvhc1p-4"
})(["border-radius:3px;object-fit:contain;width:100%;"]);
var ImageContainer = styled.div.withConfig({
  displayName: "category-tile__ImageContainer",
  componentId: "vvhc1p-5"
})(["overflow:hidden;margin-top:auto;height:100%;width:100%;display:flex;justify-content:center;position:absolute;bottom:0;padding:38.3% 12.6% 9.4%;"]);