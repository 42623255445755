/* eslint-disable @typescript-eslint/naming-convention,camelcase */
import _noop from 'lodash/noop';
import _map from 'lodash/map';
import { ValidationError } from 'yup';

import { participatingInAds } from 'utils/ads/helpers';

import { getPersistedValue } from 'shared/utils/persisted-values';

import { createTelemetryClient } from '../../clients';
import { EventTracker, AnalyticsContext } from '../../events';

import { Data, validateSchema, cartItemSchema } from './schemas';
import { getCommonProps, getItemProps, getCartItemProps, getCartTotalValue } from './utils';

const loggingEnabled = !!getPersistedValue('analytics:telemetryTracker');

type Tracker<T> = (payload: T, context: AnalyticsContext, oldContext?: AnalyticsContext) => void;

// support both types of function signiture
export function trackerFactory<T>(tracker: Tracker<T>): Tracker<T> {
  return (payload, context, oldContext) => {
    const telemetryEnabledForDispo = participatingInAds(context.featureFlags);

    if (
      !context.featureFlags[`growth.ads.user-events.enabled`] ||
      !telemetryEnabledForDispo ||
      context.activeMenuVariant === 'plus'
    ) {
      return;
    }

    try {
      tracker(payload, context, oldContext);
    } catch (err) {
      if (err instanceof ValidationError && loggingEnabled) {
        console.error(err);
      } else if (!(err instanceof ValidationError)) {
        throw err;
      }
    }
  };
}

export const createTelemetryTracker = (): EventTracker => {
  const client = createTelemetryClient();

  return {
    onContextChange: trackerFactory((_updates, newContext, oldContext) => {
      const { featureFlags, activePath } = newContext;

      if (
        featureFlags[`growth.ads.user-event-expansion.rollout`] &&
        activePath?.concrete !== oldContext?.activePath?.concrete
      ) {
        const data: Data = {
          type: 'viewedPage',
          payload: getCommonProps(newContext),
        };

        validateSchema(data);
        void client.post(data);
      }
    }),

    addedProductToCart: trackerFactory((payload, context) => {
      const data: Data = {
        type: 'addedProductToCart',
        payload: {
          ...getCommonProps(context),
          item: getCartItemProps(payload),
        },
      };

      validateSchema(data);
      void client.post(data);
    }),

    placedOrder: trackerFactory((payload, context) => {
      // exclude invalid items
      const items = _map(context.activeOrder?.cart, getCartItemProps).filter((item: unknown) =>
        cartItemSchema.isValidSync(item)
      );

      const commonProps = getCommonProps(context);

      const data: Data = {
        type: 'placedOrder',
        payload: {
          ...commonProps,
          items,
          total: getCartTotalValue(context),
          order_id: payload.order.id,
        },
      };

      validateSchema(data);
      void client.post(data, { resetSessionOnSuccess: commonProps.menu_variant === 'kiosk' });
    }),

    searchResults: trackerFactory((payload, context) => {
      if (!context.featureFlags[`growth.ads.user-event-expansion.rollout`]) {
        return;
      }

      const data: Data = {
        type: 'searchResults',
        payload: {
          ...getCommonProps(context),
          query: payload.query,
        },
      };

      validateSchema(data);
      void client.post(data);
    }),

    viewedProduct: trackerFactory((payload, context) => {
      if (!context.featureFlags[`growth.ads.user-event-expansion.rollout`]) {
        return;
      }

      const data: Data = {
        type: 'viewedProduct',
        payload: {
          ...getCommonProps(context),
          item: getItemProps(payload),
        },
      };

      validateSchema(data);
      void client.post(data);
    }),

    sponsoredBannerImpression: trackerFactory((payload, context) => {
      if (!context.featureFlags[`growth.ads.tracking-through-events.rollout`]) {
        return;
      }

      const { products, impressionUrl } = payload;

      const data: Data = {
        type: 'sponsoredBannerImpression',
        payload: {
          ...getCommonProps(context),
          tracking_url: impressionUrl,
          items: products.map((item) => getItemProps({ product: item })),
        },
      };

      validateSchema(data);
      void client.post(data);
    }),

    sponsoredBannerClicked: trackerFactory((payload, context) => {
      if (!context.featureFlags[`growth.ads.tracking-through-events.rollout`]) {
        return;
      }

      const { products, clickUrl } = payload;

      const data: Data = {
        type: 'sponsoredBannerClicked',
        payload: {
          ...getCommonProps(context),
          tracking_url: clickUrl,
          items: products.map((item) => getItemProps({ product: item })),
        },
      };

      validateSchema(data);
      void client.post(data);
    }),

    sponsoredProductImpression: trackerFactory((payload, context) => {
      if (!context.featureFlags[`growth.ads.tracking-through-events.rollout`]) {
        return;
      }

      const { product } = payload;

      const data: Data = {
        type: 'sponsoredProductImpression',
        payload: {
          ...getCommonProps(context),
          tracking_url: product.adTrackers?.impressionUrl,
          item: getItemProps({ product }),
        },
      };

      validateSchema(data);
      void client.post(data);
    }),

    sponsoredProductClicked: trackerFactory((payload, context) => {
      if (!context.featureFlags[`growth.ads.tracking-through-events.rollout`]) {
        return;
      }

      const { product } = payload;

      const data: Data = {
        type: 'sponsoredProductClicked',
        payload: {
          ...getCommonProps(context),
          tracking_url: product.adTrackers?.clickUrl,
          item: getItemProps({ product }),
        },
      };

      validateSchema(data);
      void client.post(data);
    }),

    // Noop events
    accountLogin: _noop,
    buyItAgainImpression: _noop,
    buyItAgainViewAllClicked: _noop,
    checkoutStarted: _noop,
    viewedCheckout: _noop,
    checkoutSession: _noop,
    removedProductFromCart: _noop,
    searchedForProduct: _noop,
    cartViewed: _noop,
    productClicked: _noop,
    imageBannerClicked: _noop,
    paymentsError: _noop,
    dutchiePayEnrollmentButtonClicked: _noop,
    dutchiePayLearnMoreButtonClicked: _noop,
    dutchiePayCTAButtonClicked: _noop,
    dutchiePayEnrollmentStep: _noop,
    dutchiePayConnectBankStep: _noop,
    dutchiePayPlaidOnEvent: _noop,
    dutchiePayInstoreAccountLinkViewed: _noop,
    dutchiePayInstoreLoginViewed: _noop,
    dutchiePayInstoreCartViewed: _noop,
    dutchiePayInstoreCartApprovalClicked: _noop,
    dutchiePayInstoreCartApprovalSucceeded: _noop,
    dutchiePayInstoreCartApprovalFailed: _noop,
    experimentImpression: _noop,
    gaGTMClickEvent: _noop,
  };
};
