import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Chip from 'components/core/chip';

type DiscountChipProps = {
  label: ReactNode;
};

export function DiscountChip(props: DiscountChipProps): JSX.Element {
  const { label } = props;
  return <StyledChip label={label} />;
}

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background-color: ${({ theme }) => theme.colors.red[60]};
    color: ${({ theme }) => theme.colors.white};
  }
`;
