import React, { useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import usePaths from 'hooks/use-paths';
import useMenuFilterOptions from 'hooks/use-menu-filter-options';
import { Link, SearchInput } from 'components/core';
import useTranslation from 'src/hooks/use-translation';
import { tracker } from 'src/utils/analytics';

type BrandListItemProps = {
  label: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

function BrandListItem({ label, onClick }: BrandListItemProps): JSX.Element {
  const { href, route } = usePaths();
  const brandSlug = _.kebabCase(label);
  // eslint-disable jsx-a11y/anchor-is-invalid
  return (
    <ListItem>
      <Link href={`${href}/brands/${brandSlug}`} route={`${route}/brands/${brandSlug}`}>
        <a href={`${href}/brands/${brandSlug}`} onClick={onClick}>
          {label}
        </a>
      </Link>
    </ListItem>
  );
}

type BrandsProps = {
  className?: string;
  onClose?: React.MouseEventHandler<HTMLAnchorElement>;
};

export default function Brands({ className, onClose }: BrandsProps): JSX.Element {
  const [search, setSearch] = useState('');
  const { brands } = useMenuFilterOptions({ skipCurrentFilters: true });

  const consolidatedBrands = _.uniqBy(brands, `name`);

  const filteredBrands = _.filter(consolidatedBrands, (b) =>
    search.length > 2 ? _.includes(_.toLower(b.name), search) : true
  );

  const { t } = useTranslation();

  const handleBrandSelection = (brandName: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    tracker.gaGTMClickEvent({
      eventLabel: `brands > ${brandName}`,
      eventCategory: `navigation click`,
      eventAction: `main menu`,
    });
    if (onClose) {
      onClose(event);
    }
  };

  return (
    <Container className={className}>
      <SearchInput
        placeholder={t('search-brands-placeholder', 'Search Brands...')}
        value={search}
        onChange={(e) => setSearch(_.toLower(e.target.value))}
        onClear={() => setSearch('')}
        withClearButton
      />
      <BrandList>
        {_.map(filteredBrands, ({ id, name }) => (
          <BrandListItem key={id} label={name} onClick={(event) => handleBrandSelection(name, event)} />
        ))}
      </BrandList>
    </Container>
  );
}

const Container = styled.div`
  padding: 43px 16px 82px 16px;
  max-height: 100%;
`;

const BrandList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 17px;
`;

const ListItem = styled.li`
  padding: 0;

  > a {
    color: ${({ theme }) => theme.colors.primaryBlack};
    font-size: 15px;
    line-height: 40px;
  }
`;
