import React from 'react';
import styled, { useTheme } from 'styled-components';
import TinyColor from 'tinycolor2';

import Button from 'components/core/button';
import { isWeightedProduct } from 'shared/helpers/products';
import { Product } from 'types/graphql-overrides';

type UseGetButtonColorsReturn = {
  backgroundColor: string;
  color: string;
  backgroundColorHover: string;
  colorHover: string;
};

function getCopy(product: Product): string {
  const { Options } = product;
  if (isWeightedProduct(product) && Options?.length && Options.length > 1) {
    return `Select Weight`;
  }
  return `Add to cart`;
}

function useGetButtonColors(): UseGetButtonColorsReturn {
  const { colors, customized } = useTheme();
  const backgroundColor = TinyColor(customized.colors.buttonsLinks).setAlpha(0.1).toString();
  const color = TinyColor.mix(customized.colors.buttonsLinks, '#000000', 20).toString();
  const backgroundColorHover = customized.colors.buttonsLinks;
  const colorHover = colors.white;

  return {
    backgroundColor,
    color,
    backgroundColorHover,
    colorHover,
  };
}
type AddToCartButtonProps = {
  onClick: () => void;
  buttonCopy?: string | null;
  isEmbeddedCarousel?: boolean;
  product: Product;
};

export const AddToCartButton = ({
  buttonCopy,
  isEmbeddedCarousel = false,
  onClick,
  product,
}: AddToCartButtonProps): JSX.Element => {
  const buttonColors = useGetButtonColors();

  // Only use the Add-To-Cart handler if we're not in an embedded carousel context.
  // Embedded carousel clicks should fall through to their parent product card's click handler (i.e. View Item)
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <StyledButton
      onClick={isEmbeddedCarousel ? undefined : handleClick}
      noDim
      {...buttonColors}
      data-testid='add-to-cart-button'
    >
      {buttonCopy ?? getCopy(product)}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  margin-top: 9px;
  transition: all 0.2s;
  ${({ backgroundColor, backgroundColorHover, color, colorHover }) => `
    background-color: ${String(backgroundColor)};
    color: ${String(color)};

    &:hover {
      background-color: ${String(backgroundColorHover)};
      color: ${String(colorHover)};
    }
  `}
`;
