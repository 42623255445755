import React from 'react';
import styled from 'styled-components';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useColors } from 'src/contexts/colors';
import useTranslation from 'hooks/use-translation';

import { DesktopOnly, MobileOnly } from 'shared/components';
import { MyAccountLink } from 'dispensary/core-menu/components/core-menu-header/my-account-link';
import PoweredByDutchie from 'dispensary/core-menu/components/powered-by-dutchie';
import StatusMenuSelect from 'dispensary/core-menu/home/components/status-menu-select';
import StoreStatus from 'dispensary/core-menu/components/core-menu-header/store-status';
import { Subheader, VerticalDivider } from 'components/core';

import { LayoutWrapper } from './layout-wrapper';

export function AgeVerificationGateLayout({ children }): JSX.Element | null {
  const { dispensary } = useDispensary();
  const colors = useColors();
  const { t } = useTranslation();

  if (!dispensary) {
    return null;
  }

  const { recDispensary } = dispensary;

  return (
    <LayoutWrapper>
      <Container>
        <StyledHeader isDutchieMain={false}>
          <DesktopOnly>
            <StoreInfoContainer>
              <StoreStatus dispensary={dispensary} omitInfoLink />
              <VerticalDivider _color={colors.blueGrey[80]} />
              <MenuTypeContainer>
                <span>{t('common.menu', 'Menu')}</span>
                <span>{recDispensary ? t('common.recreational', 'Recreational') : t('common.medical', 'Medical')}</span>
              </MenuTypeContainer>
              <VerticalDivider _color={colors.blueGrey[80]} />
            </StoreInfoContainer>
          </DesktopOnly>
          <MyAccountLink />
        </StyledHeader>
        <MobileOnly>
          <StatusMenuSelect dispensary={dispensary} m='0' />
        </MobileOnly>
      </Container>
      {children}
      <PoweredByDutchie />
    </LayoutWrapper>
  );
}

const Container = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
  box-shadow: 0px 3px 1px ${({ theme }) => theme.colors.sectionShadow};
`;

const StyledHeader = styled(Subheader)`
  background: ${({ theme }) => theme.customized.colors.navBar};
  border: none;
  box-shadow: none;
  flex-grow: 0;
  padding: 0 20px;
  z-index: 5;

  > div > div {
    justify-content: flex-end;
  }

  @media (min-width: 1240px) {
    padding: 0;
  }
`;

const StoreInfoContainer = styled.div`
  display: flex;

  > span:not(:last-of-type),
  > div {
    margin-right: 22px;
  }
`;

const MenuTypeContainer = styled.div`
  color: ${({ theme }) => theme.colors.grey[30]};
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: bold;
  justify-content: center;

  span:first-child {
    color: ${({ theme }) => theme.colors.grey[60]};
    text-transform: uppercase;
  }
`;
