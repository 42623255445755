import React, { memo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';
import _ from 'lodash';

import useColors from 'hooks/use-colors';
import usePaths from 'hooks/use-paths';
import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';
import useMenuFilterOptions from 'hooks/use-menu-filter-options';
import useDispensaryCategories from 'src/dispensary/hooks/use-dispensary-categories';
import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';

import MenuIcon from 'assets/menu-icon';
import { DesktopOnly, MobileOnly, Clickable } from 'shared/components';
import { Link } from 'components/core';
import { heading4 } from 'components/core/typography';
import CategoriesDropdown from 'components/categories-dropdown';
import ROUTES from 'src/routes';
import { tracker } from 'src/utils/analytics';
import MenuDrawer from './menu-drawer';
import { useHideDiscountFromConsumer } from 'shared/hooks/use-hide-discounts-from-consumer';

const { BRANDS, INFO, SPECIALS, DISPENSARY_CNAME } = ROUTES;

const TabLink = observer(({ path, text }) => {
  const { route } = useRouter();
  const { href: baseHref, route: baseRoute } = usePaths();
  const { isDutchieMain } = useUI();

  const Anchor = isDutchieMain ? MainAnchor : EmbeddedAnchor;
  const tabHref = `${baseHref}${path}`;
  const tabRoute = `${baseRoute}${path}`;
  const isHome = path.length === 0;
  const isHomeActive = route === DISPENSARY_CNAME;
  const isActive = _.startsWith(route, tabRoute);
  const isSpecialsActive = path.includes('specials');
  const isBrands = path.includes('brands');

  const handleTabLinkAnalyticsTracking = () => {
    if (isSpecialsActive) {
      tracker.gaGTMClickEvent({
        eventCategory: `navigation click`,
        eventAction: `main menu`,
        eventLabel: `specials`,
      });
    }
    if (isBrands) {
      tracker.gaGTMClickEvent({
        eventAction: `main menu`,
        eventCategory: `navigation click`,
        eventLabel: `brands`,
      });
    }
    if (isHome) {
      tracker.gaGTMClickEvent({
        eventAction: `main menu`,
        eventCategory: `navigation click`,
        eventLabel: `home`,
      });
    }
  };

  return (
    <Link route={tabRoute} href={tabHref}>
      <Anchor
        active={isHome ? isHomeActive : isActive}
        href={tabHref}
        isDutchieMain={isDutchieMain}
        onClick={handleTabLinkAnalyticsTracking}
      >
        {text}
      </Anchor>
    </Link>
  );
});

function NavLinks(props) {
  const { dispensary } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isDutchieMain, showSearchInput } = useUI();
  const colors = useColors();
  const { t } = useTranslation();
  const { brands, hasProductsOnSpecial } = useMenuFilterOptions({
    skipCurrentFilters: true,
  });
  const hideDiscount = useHideDiscountFromConsumer(dispensary);
  const currentCategory = useDispensaryCategory();
  const { categories } = useDispensaryCategories();
  const HOME = '';

  return (
    <>
      <MobileOnly>
        <MobileMenuContainer onClick={() => setDrawerOpen(true)}>
          <MenuIcon fill={colors.grey[75]} />
          {!showSearchInput && <MobileMenuLabel>{t('common.menu', 'Menu')}</MobileMenuLabel>}
        </MobileMenuContainer>
        <MenuDrawer dispensary={dispensary} open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      </MobileOnly>
      <DesktopOnly display={isDutchieMain ? 'contents' : 'initial'}>
        <Links>
          <TabLink path={HOME} text={t('common.home', 'Home')} />
          <CategoriesDropdown categories={categories} currentCategory={currentCategory} isDutchieMain={isDutchieMain} />
          {!_.isEmpty(brands) && <TabLink path={BRANDS} text={t('common.brand_plural', 'Brands')} />}
          {hasProductsOnSpecial && !hideDiscount && (
            <TabLink path={SPECIALS} text={t('common.special_plural', 'Specials')} />
          )}
          {isDutchieMain && <TabLink path={INFO} text={t('common.info', 'Info')} />}
        </Links>
      </DesktopOnly>
    </>
  );
}

export default memo(observer(NavLinks));

const Links = styled.nav`
  display: flex;
  margin-left: -10px;
  height: 100%;
`;

const anchorStyles = css`
  ${heading4}

  cursor: pointer;
  font-size: 14px;
  padding: 10px;
  margin-right: 20px;

  color: ${({ theme }) => theme.colors.primaryBlack};
`;

const EmbeddedAnchor = styled.a`
  ${anchorStyles}
`;

const MainAnchor = styled.a`
  ${anchorStyles}

  line-height: 25px;
  padding-top: 19px;

  color: ${({ active, theme }) => (active ? theme.colors.primaryGreen : theme.colors.grey[30])};
  border-bottom: ${({ active, theme }) => (active ? `2px ${theme.colors.primaryGreen} solid` : `none`)};
`;

/* mobile menu */
const MobileMenuContainer = styled(Clickable)`
  display: flex;
  flex: 1 auto;
  height: 20px;

  svg {
    cursor: pointer;
  }
`;

const MobileMenuLabel = styled.span`
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 15px;

  color: ${({ theme }) => theme.colors.grey[55]};
`;
