import { useMemo } from 'react';
import { ApolloError } from 'apollo-client';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Product } from 'types/graphql-overrides';
import { GqlGetSponsoredProductsInput, useGetSponsoredProductsQuery } from 'types/graphql';

import { parseProduct } from 'src/utils/helpers/product';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { ADS_FETCH_POLICY } from '../../helpers';

type PrependWithSponsoredProductsArgs = Omit<GqlGetSponsoredProductsInput, 'dispensaryId'> & {
  skip?: boolean;
  products: Product[];
  loading: boolean;
  error: ApolloError | undefined;
};

type PrependWithSponsoredProducts = {
  products: Product[];
  loading: boolean;
  error: ApolloError | undefined;
};

export function usePrependWithSponsoredProducts({
  skip = false,
  products,
  loading: productsLoading,
  error: productsError,
  limit,
  inventoryId,
  category,
  searchQuery,
}: PrependWithSponsoredProductsArgs): PrependWithSponsoredProducts {
  const { dispensary } = useDispensary();
  const dispensaryId = dispensary?.id;
  const flags = useFlags();

  const sponsoredProductsLimit = flags['growth.ads.sponsored-products.increased-display-count'] ?? 1;

  const { data, loading: sponsoredProductsLoading, error: sponsoredProductsError } = useGetSponsoredProductsQuery({
    fetchPolicy: ADS_FETCH_POLICY,
    skip: skip || !dispensaryId || productsLoading || productsError instanceof Error,
    variables: {
      input: {
        limit,
        dispensaryId,
        inventoryId,
        category,
        searchQuery,
      },
    },
  });

  const sponsoredProductsData = data?.getSponsoredProducts;

  const serializedProducts = useMemo(() => {
    if (productsLoading || sponsoredProductsLoading || productsError) {
      return [];
    }

    if (!sponsoredProductsData?.length || sponsoredProductsError) {
      return products;
    }

    const sponsoredProducts = sponsoredProductsData.slice(0, sponsoredProductsLimit).map(parseProduct);

    const sponsoredProductsIds = new Set(sponsoredProducts.map((item) => item.id));

    return sponsoredProducts.concat(products.filter((item) => !sponsoredProductsIds.has(item.id)));
  }, [
    products,
    productsLoading,
    productsError,
    sponsoredProductsData,
    sponsoredProductsLoading,
    sponsoredProductsError,
    sponsoredProductsLimit,
  ]);

  return {
    products: serializedProducts,
    loading: productsLoading || sponsoredProductsLoading,
    error: productsError,
  };
}
