import { useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useObserver } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import type { LDContext } from 'launchdarkly-js-client-sdk';
import { v4 as uuidV4 } from 'uuid';

import { isBrowser } from 'utils/misc-utils';
import useUser from 'hooks/use-user';
import useUI from 'hooks/use-ui';
import usePaths from 'hooks/use-paths';
import useCart from 'hooks/use-cart';
import useFeatureFlags from 'hooks/use-feature-flags';
import { EXPERIMENT_FLAGS, trackExperimentImpressions } from 'src/hooks/use-experiment';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import usePersistedValue from 'hooks/use-persisted-value';
import meConsumer from 'shared/graphql/user/queries/me-consumer.gql';
import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';

import ROUTES from 'src/routes';
import { GqlMeConsumerQuery } from 'types/graphql';
import { useCheckAgeVerification } from 'src/dispensary/core-menu/age-verification-gate/hooks';

export function useLaunchDarklyClientContext({ user, dispensary }): void {
  const FeatureFlags = useFeatureFlags();

  const userId = user.id;
  // always default to getting the persisted value
  let userKey = getPersistedValue(`launch-darkly-client-id`, null);
  // if no persisted id is found generate a new key
  if (!userKey) {
    userKey = uuidV4();
  }

  useEffect(() => {
    // get the current stored value
    const storedUserId = getPersistedValue(`launch-darkly-client-id`, null);
    // may not exist if first time visiting site or local storage was cleared
    if (!storedUserId) {
      setPersistedValue(`launch-darkly-client-id`, userKey);
    }
  }, [userKey]);

  const client = useLDClient();
  const enterpriseId = dispensary?.retailer?.enterpriseId;
  const dispensaryId = dispensary?.id;

  useEffect(() => {
    /*
     * We will always have a guest context along with a user context if the user is logged in
     * which would associate with the same context repeatedly while the guest context will be tied
     * the the customer's session/local storage key. This ensures that targeting an experiment to a
     * guest will be consistent across being logged in and logged out and if needed we can target by
     * by user which would only be a logged in customer.
     */
    const guestContext = {
      key: userKey,
      type: `guestCustomer`,
    };

    const context: LDContext = {
      kind: `multi`,
      guest: guestContext,
    };

    if (userId) {
      // eslint-disable-next-line dot-notation
      context[`user`] = {
        key: userId,
        type: `customer`,
      };
    }

    if (dispensaryId) {
      context[`ecomm-dispensary`] = {
        key: dispensaryId,
        // cName: dispensary.cName,
        // chain: dispensary.chain,
      };
    }

    if (enterpriseId) {
      context[`ecomm-enterprise`] = {
        key: enterpriseId,
      };
    }

    if (!client) {
      return;
    }

    void client.identify(context).then(() => {
      FeatureFlags.identify(context);

      if (dispensaryId || enterpriseId) {
        trackExperimentImpressions(client, EXPERIMENT_FLAGS);
      }
    });
  }, [client, userId, dispensaryId, enterpriseId, userKey, FeatureFlags]);
}

export function useUserQuery({ user, cart }): void {
  const token = usePersistedValue(user, 'token', 'access-token', false);
  const refetchRequest = useObserver(() => user.refetchRequest);
  const apolloClient = useApolloClient();

  useEffect(() => {
    async function checkUser(): Promise<void> {
      user.loading = true;

      if (token && (!user.isLoggedIn || refetchRequest)) {
        await queryMe();
      }

      if (!token && user.isLoggedIn) {
        user.logout();
        await apolloClient.resetStore();
      }
      user.loading = false;
    }

    async function queryMe(): Promise<void> {
      try {
        const { data } = await apolloClient.query<GqlMeConsumerQuery>({ query: meConsumer, fetchPolicy: `no-cache` });

        user.setUser(data.meConsumer);
        user.refetchRequest = false;

        if (data.meConsumer?.profile.address && !cart.hasAddress) {
          cart.updateAddress({ location: data.meConsumer.profile.address, residentialStatus: 'VERIFIED' });
        }
      } catch (err) {
        console.error(err);
      }
      user.loading = false;
    }

    void checkUser();
  }, [apolloClient, user, token, refetchRequest, cart]);
}

export function useStorageListener({ user }): void {
  // listen for login events on other windows
  useEffect(() => {
    if (!isBrowser()) {
      // ssr
      return;
    }
    function handleStorageEvent(event): void {
      if (event.key === 'access-token') {
        user.token = JSON.parse(event.newValue);
      }
    }
    window.addEventListener('storage', handleStorageEvent);
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('storage', handleStorageEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // FIXME: ENG-32714 fix hooks dependency
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function useSendToAgeVerificationGate({ user, UI }): void {
  const { isEmbedded, isStoreFront, isAccountPage, isLocationSelectorPage } = UI;
  const isWrongVariant = !isEmbedded && !isStoreFront;
  const userId = useObserver(() => user.id);
  const token = useObserver(() => user.token);
  const { AGE_VERIFICATION_GATE } = ROUTES;

  const { dispensary } = useDispensary();
  const { href } = usePaths({ dispensary });
  const isAgeVerificationSettingEnabled = dispensary?.storeSettings.hardAgeGateAgeVerification ?? false;
  const router = useRouter();

  const { isAgeVerified, loading } = useCheckAgeVerification({ dispensary, userId });

  const skipRedirectToAgeGate =
    isWrongVariant || !isAgeVerificationSettingEnabled || !dispensary || isAccountPage || isLocationSelectorPage;

  useEffect(() => {
    if (skipRedirectToAgeGate) {
      return;
    }

    async function getIsUserAgeVerified(): Promise<void> {
      const notVerified = !loading && !isAgeVerified;

      const notLoggedIn = !token;

      if (notVerified || notLoggedIn) {
        void router.replace(`${href}${AGE_VERIFICATION_GATE}`);
      }
    }

    void getIsUserAgeVerified();
    // we don't want router here or we get infinite renders when we push to the router stack
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AGE_VERIFICATION_GATE, href, isAgeVerified, loading, skipRedirectToAgeGate, token]);
}

export default function useUserController(): void {
  const user = useUser();
  const cart = useCart();
  const UI = useUI();

  const { dispensary: viewingDispensary } = useDispensary();
  const dispensary = useObserver(() => viewingDispensary || cart.dispensary);

  usePersistedValue(user, `userDetailsFromExternalSource`);
  useUserQuery({ user, cart });
  useStorageListener({ user });
  // eslint-disable-next-line @typescript-eslint/naming-convention
  useSendToAgeVerificationGate({ user, UI });
  useLaunchDarklyClientContext({ user, dispensary });
}
