import React from 'react';
import _times from 'lodash/times';

import { ProductMobileRowLoadingSkeleton } from 'components/core';

const CategoryPageMobileLoadingState = () => {
  return _times(5, (key) => <ProductMobileRowLoadingSkeleton key={key} />);
};

export default CategoryPageMobileLoadingState;
