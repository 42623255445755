import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  PopoverNavButton,
  PopoverNavItem,
  PopoverNavLink,
  StyledPopover,
  buttonResetStyles,
} from 'src/dispensary/core-menu/components/header/header.styles';

import useUI from 'src/hooks/use-ui';
import { GoogleLogin } from 'src/components/google-login';
import { EmbeddedAccountIcon } from 'src/assets/embedded-account-icon';
import { useUserButton } from './use-user-button';

export const UserButton = (): JSX.Element | null => {
  const {
    isUserLoggedIn,
    firstName,
    isPopoverOpen,
    popoverTriggerRef,
    setIsPopoverOpen,
    loggedInRoutes,
    loyaltyPoints,
    handleLogout,
    handleLogin,
    handleSignup,
    handlePopoverLinkClick,
    handlePopoverToggle,
    handleGoogleLoginSubmit,
  } = useUserButton();

  const flags = useFlags();
  const isLoginPlacementsFlagEnabled = flags['growth.ecomm.login-placements.rollout'] ?? false;

  const { isEmbedded } = useUI();
  const hasLoyaltyPoints = Number.isInteger(Number.parseInt(loyaltyPoints, 10));

  return (
    <>
      <Button
        onClick={() => handlePopoverToggle(isUserLoggedIn ? 'Account Button' : 'Login Button')}
        ref={popoverTriggerRef}
        data-testid='user-button'
      >
        <EmbeddedAccountIcon fill='currentColor' />
        {isUserLoggedIn ? (
          <div>
            <FirstName>{firstName}</FirstName> {hasLoyaltyPoints ? `${loyaltyPoints} pts` : 'Account'}
          </div>
        ) : (
          'Login'
        )}
      </Button>

      <StyledPopover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={popoverTriggerRef.current}
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        data-testid='user-button-popover'
        $offsetTop
      >
        <nav>
          {isUserLoggedIn ? (
            <>
              <PopoverFirstName>Hi, {firstName}</PopoverFirstName>
              {loggedInRoutes.map(
                ({ href, label, hide }) =>
                  !hide && (
                    <Link href={href} key={label}>
                      <PopoverNavLink href={href} onClick={() => handlePopoverLinkClick(label)}>
                        {label}
                      </PopoverNavLink>
                    </Link>
                  )
              )}
              <PopoverNavButton onClick={handleLogout}>Logout</PopoverNavButton>
            </>
          ) : (
            <>
              <PopoverNavButton onClick={handleLogin} data-testid='login'>
                Login
              </PopoverNavButton>
              <PopoverNavButton onClick={handleSignup} data-testid='create-account'>
                Create Account
              </PopoverNavButton>
              {isLoginPlacementsFlagEnabled && (
                <StyledGoogleLogin
                  redirectUrl={window.location.href}
                  onSubmit={handleGoogleLoginSubmit}
                  $isEmbedded={isEmbedded}
                />
              )}
            </>
          )}
        </nav>
      </StyledPopover>
    </>
  );
};

const Button = styled.button`
  ${buttonResetStyles}

  align-items: center;
  color: currentColor;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  gap: 8px;
  justify-content: space-between;
  line-height: ${18 / 13};

  @media (min-width: 880px) {
    gap: 12px;
  }
`;

const PopoverFirstName = styled(PopoverNavItem)`
  border-bottom: 1px solid #d9d9d9;
  margin: 0 24px;
  padding-left: 0;
  padding-right: 0;
  width: auto;

  @media (min-width: 880px) {
    display: none;
  }
`;

const googleLoginOverrideStyles = css`
  background: none;
  border: none;
  border-radius: 0;
  color: ${({ theme }) => theme.colors.grey[30]};
  height: 40px;
  margin-top: 1px;
  padding: 0 24px;
  position: relative;

  &::before {
    background: #c8d2db;
    content: '';
    height: 1px;
    left: 24px;
    position: absolute;
    right: 24px;
    top: -1px;
  }

  svg {
    height: 16px;
    width: 16px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryGrey};
    box-shadow: none;
  }
`;

// For embedded menus, GoogleLogin is just a button, for other cases it's a form with a button as a child
const StyledGoogleLogin = styled(GoogleLogin)<{ $isEmbedded: boolean }>`
  ${({ $isEmbedded }) =>
    $isEmbedded
      ? googleLoginOverrideStyles
      : css`
          > button {
            ${googleLoginOverrideStyles}
          }
        `}
`;

const FirstName = styled.div`
  color: #a0a7ab;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  max-width: 12ch;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 879px) {
    display: none;
  }
`;
