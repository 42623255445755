import React from 'react';
import styled, { css } from 'styled-components';

import useDispensaryCategories from 'src/dispensary/hooks/use-dispensary-categories';
import { useDispensaryDisclaimer } from 'src/dispensary/hooks/use-dispensary-disclaimer';

import DisclaimerText from 'src/dispensary/components/disclaimer-text';
import PoweredByDutchie from 'src/dispensary/core-menu/components/powered-by-dutchie';
import CategoryTilesGrid from 'components/category-tiles-grid';

/**
 * @param {{
 *   displayDisclaimer?: boolean;
 *   hideCategories?: boolean;
 *   hideHeader?: boolean;
 *   props?: any;
 * }}
 */
export default function CoreMenuFooter({ displayDisclaimer = true, hideCategories, hideHeader, props }) {
  const { categories } = useDispensaryCategories();

  const { disclaimerIsBlank, disclaimerTextHtml } = useDispensaryDisclaimer();

  const displayDisclaimerAbove = displayDisclaimer && !disclaimerIsBlank && hideCategories;
  const displayDisclaimerBelow = displayDisclaimer && !disclaimerIsBlank && !hideCategories;

  return (
    <>
      {displayDisclaimerAbove && <DisclaimerText m='0 auto 50px' html={disclaimerTextHtml} />}
      {!hideCategories && <StyledCategoryTilesGrid categories={categories} hideHeader={hideHeader} {...props} />}
      {displayDisclaimerBelow && (
        <BottomDisclaimerContainer>
          <DisclaimerText m='0 auto 50px' className='disclaimer-text' html={disclaimerTextHtml} />
        </BottomDisclaimerContainer>
      )}

      <PoweredByDutchie />
    </>
  );
}

const BottomDisclaimerContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${({ theme }) => theme.colors.grey[95]};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .disclaimer-text {
      margin: 25px auto 25px;
    }
    background: ${({ theme }) => theme.colors.white};
  }
`;
const StyledCategoryTilesGrid = styled(CategoryTilesGrid)`
  ${({ hideHeader }) =>
    hideHeader &&
    css`
      margin-top: 40px;
    `}

  padding: 50px 25px 60px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0 25px 25px;
  }
`;
