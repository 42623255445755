/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { isBrowser } from 'utils/misc-utils';

import { Session } from './session';

const session = Session.getInstance();

export function useInitSession(UI: any): void {
  const router = useRouter();

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    session.variant = UI.variant;

    void session.identify();
  }, [router.pathname, UI.variant]);
}
