/* eslint-disable @typescript-eslint/naming-convention,camelcase */
import { AnalyticsContext } from '../../events';

export const getItemProps = ({ product }: any): Record<string, unknown> => ({
  id: product.id,
  library_product_id: product.libraryProductId,
  library_product_score: product.libraryProductScore,
  brand_id: product.brandId,
  parent_brand_id: product.brand?.parentBrandId,
});

export const getCartItemProps = ({ product, quantity, option, basePrice, price }: any): Record<string, unknown> => ({
  ...getItemProps({ product }),
  price: basePrice ?? price ?? product?.Prices?.[0],
  quantity,
  option,
});

export const getCartTotalValue = (context: AnalyticsContext): string | undefined =>
  context.activeCart?.subtotal?.toFixed(2);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getCommonProps = ({ activeDispensary, activeMenuVariant }: AnalyticsContext) => ({
  dispensary_id: activeDispensary?.id,
  menu_variant: activeMenuVariant,
});
