import { useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function useFetchTracker(): (url: string, retries?: number, backoff?: number) => void {
  const flags = useFlags();

  return useCallback(
    async function fetchWithRetry(url: string, retries = 3, backoff = 300): Promise<void> {
      if (!flags['growth.ads.tracking.enabled'] || flags['growth.ads.tracking-through-events.rollout']) {
        return;
      }

      try {
        const response = await fetch(url);

        if (response.ok) {
          return;
        }

        if (response.status >= 500 && response.status < 600) {
          throw new Error(`Server Error: ${response.status}`);
        }
      } catch (e) {
        if (retries > 1) {
          await new Promise((res) => setTimeout(res, backoff));
          await fetchWithRetry(url, retries - 1, backoff * 2);
        }

        console.error('useFetchTracker failed', e);
      }
    },
    [flags]
  );
}
