import React from 'react';
import styled from 'styled-components';

import Chip from 'components/core/chip';
import { Typography } from 'src/typography';
import useTranslation from 'hooks/use-translation';

export function StaffPickChip(): JSX.Element {
  const { t } = useTranslation();

  return (
    <StyledChip
      label={
        <Typography.Label tag='span' size='xsmall' weight='bold'>
          {t('marketplace.staff-picks', 'Staff Pick')}
        </Typography.Label>
      }
    />
  );
}

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background-color: ${({ theme }) => theme.colors.primaryGreen};
    color: ${({ theme }) => theme.colors.white};
  }
`;
